/**
 * Do not edit directly
 * Generated on Fri, 15 Nov 2024 20:59:20 GMT
 */

module.exports = {
  "core": {
    "border-radius": {
      "0": {
        "value": "0",
        "type": "borderRadius",
        "attributes": {
          "category": "size"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "0",
          "type": "borderRadius",
          "attributes": {
            "category": "size"
          }
        },
        "name": "border-radius-0",
        "path": [
          "core",
          "border-radius",
          "0"
        ]
      },
      "1": {
        "value": "0.25rem",
        "type": "borderRadius",
        "attributes": {
          "category": "size"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "0.25rem",
          "type": "borderRadius",
          "attributes": {
            "category": "size"
          }
        },
        "name": "border-radius-1",
        "path": [
          "core",
          "border-radius",
          "1"
        ]
      },
      "2": {
        "value": "0.5rem",
        "type": "borderRadius",
        "attributes": {
          "category": "size"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "0.5rem",
          "type": "borderRadius",
          "attributes": {
            "category": "size"
          }
        },
        "name": "border-radius-2",
        "path": [
          "core",
          "border-radius",
          "2"
        ]
      },
      "3": {
        "value": "0.75rem",
        "type": "borderRadius",
        "attributes": {
          "category": "size"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "0.75rem",
          "type": "borderRadius",
          "attributes": {
            "category": "size"
          }
        },
        "name": "border-radius-3",
        "path": [
          "core",
          "border-radius",
          "3"
        ]
      },
      "4": {
        "value": "1rem",
        "type": "borderRadius",
        "attributes": {
          "category": "size"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "1rem",
          "type": "borderRadius",
          "attributes": {
            "category": "size"
          }
        },
        "name": "border-radius-4",
        "path": [
          "core",
          "border-radius",
          "4"
        ]
      },
      "5": {
        "value": "1.25rem",
        "type": "borderRadius",
        "attributes": {
          "category": "size"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "1.25rem",
          "type": "borderRadius",
          "attributes": {
            "category": "size"
          }
        },
        "name": "border-radius-5",
        "path": [
          "core",
          "border-radius",
          "5"
        ]
      },
      "6": {
        "value": "1.5rem",
        "type": "borderRadius",
        "attributes": {
          "category": "size"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "1.5rem",
          "type": "borderRadius",
          "attributes": {
            "category": "size"
          }
        },
        "name": "border-radius-6",
        "path": [
          "core",
          "border-radius",
          "6"
        ]
      },
      "7": {
        "value": "1.75rem",
        "type": "borderRadius",
        "attributes": {
          "category": "size"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "1.75rem",
          "type": "borderRadius",
          "attributes": {
            "category": "size"
          }
        },
        "name": "border-radius-7",
        "path": [
          "core",
          "border-radius",
          "7"
        ]
      },
      "8": {
        "value": "2rem",
        "type": "borderRadius",
        "attributes": {
          "category": "size"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "2rem",
          "type": "borderRadius",
          "attributes": {
            "category": "size"
          }
        },
        "name": "border-radius-8",
        "path": [
          "core",
          "border-radius",
          "8"
        ]
      },
      "1px": {
        "value": "0.063rem",
        "type": "borderRadius",
        "attributes": {
          "category": "size"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "0.063rem",
          "type": "borderRadius",
          "attributes": {
            "category": "size"
          }
        },
        "name": "border-radius-1px",
        "path": [
          "core",
          "border-radius",
          "1px"
        ]
      },
      "2px": {
        "value": "0.125rem",
        "type": "borderRadius",
        "attributes": {
          "category": "size"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "0.125rem",
          "type": "borderRadius",
          "attributes": {
            "category": "size"
          }
        },
        "name": "border-radius-2px",
        "path": [
          "core",
          "border-radius",
          "2px"
        ]
      }
    },
    "brand": {
      "czr-pine": {
        "value": "#173432",
        "type": "color",
        "description": "A primary color for Caesars Sportsbook.",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#173432",
          "type": "color",
          "description": "A primary color for Caesars Sportsbook.",
          "attributes": {
            "category": "color"
          }
        },
        "name": "brand-czr-pine",
        "path": [
          "core",
          "brand",
          "czr-pine"
        ]
      },
      "czr-light-gold": {
        "value": "#C5A459",
        "type": "color",
        "description": "A primary color for Caesars Sportsbook.",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#C5A459",
          "type": "color",
          "description": "A primary color for Caesars Sportsbook.",
          "attributes": {
            "category": "color"
          }
        },
        "name": "brand-czr-light-gold",
        "path": [
          "core",
          "brand",
          "czr-light-gold"
        ]
      },
      "czr-dark-gold": {
        "value": "#a0875b",
        "type": "color",
        "description": "A primary color for Caesars Sportsbook.",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#a0875b",
          "type": "color",
          "description": "A primary color for Caesars Sportsbook.",
          "attributes": {
            "category": "color"
          }
        },
        "name": "brand-czr-dark-gold",
        "path": [
          "core",
          "brand",
          "czr-dark-gold"
        ]
      },
      "czr-cool-gray": {
        "value": "#E8E3DE",
        "type": "color",
        "description": "A secondary color for Caesars Sportsbook.",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#E8E3DE",
          "type": "color",
          "description": "A secondary color for Caesars Sportsbook.",
          "attributes": {
            "category": "color"
          }
        },
        "name": "brand-czr-cool-gray",
        "path": [
          "core",
          "brand",
          "czr-cool-gray"
        ]
      },
      "czr-dark-gray": {
        "value": "#46494d",
        "type": "color",
        "description": "A secondary color for Caesars Sportsbook.",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#46494d",
          "type": "color",
          "description": "A secondary color for Caesars Sportsbook.",
          "attributes": {
            "category": "color"
          }
        },
        "name": "brand-czr-dark-gray",
        "path": [
          "core",
          "brand",
          "czr-dark-gray"
        ]
      },
      "wh-yellow": {
        "value": "#FFB81C",
        "type": "color",
        "description": "A primary color for William Hill Sportsbook.",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#FFB81C",
          "type": "color",
          "description": "A primary color for William Hill Sportsbook.",
          "attributes": {
            "category": "color"
          }
        },
        "name": "brand-wh-yellow",
        "path": [
          "core",
          "brand",
          "wh-yellow"
        ]
      },
      "wh-light-blue": {
        "value": "#32C1DF",
        "type": "color",
        "description": "A primary color for William Hill Sportsbook.",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#32C1DF",
          "type": "color",
          "description": "A primary color for William Hill Sportsbook.",
          "attributes": {
            "category": "color"
          }
        },
        "name": "brand-wh-light-blue",
        "path": [
          "core",
          "brand",
          "wh-light-blue"
        ]
      },
      "wh-blue": {
        "value": "#041E42",
        "type": "color",
        "description": "A primary color for William Hill Sportsbook.",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#041E42",
          "type": "color",
          "description": "A primary color for William Hill Sportsbook.",
          "attributes": {
            "category": "color"
          }
        },
        "name": "brand-wh-blue",
        "path": [
          "core",
          "brand",
          "wh-blue"
        ]
      },
      "dark-red": {
        "value": "#BA3330",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#BA3330",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "brand-dark-red",
        "path": [
          "core",
          "brand",
          "dark-red"
        ]
      },
      "palace-red": {
        "value": "#F51C21",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#F51C21",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "brand-palace-red",
        "path": [
          "core",
          "brand",
          "palace-red"
        ]
      },
      "horseshoe-honey": {
        "value": "#EBC28A",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#EBC28A",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "brand-horseshoe-honey",
        "path": [
          "core",
          "brand",
          "horseshoe-honey"
        ]
      },
      "horseshoe-midnight": {
        "value": "#263456",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#263456",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "brand-horseshoe-midnight",
        "path": [
          "core",
          "brand",
          "horseshoe-midnight"
        ]
      },
      "horseshoe-cream": {
        "value": "#FFF2D9",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#FFF2D9",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "brand-horseshoe-cream",
        "path": [
          "core",
          "brand",
          "horseshoe-cream"
        ]
      }
    },
    "rewards": {
      "seven-stars": {
        "value": "#892031",
        "type": "color",
        "description": "The color for to represent Seven Stars status for Caesars Rewards.",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#892031",
          "type": "color",
          "description": "The color for to represent Seven Stars status for Caesars Rewards.",
          "attributes": {
            "category": "color"
          }
        },
        "name": "rewards-seven-stars",
        "path": [
          "core",
          "rewards",
          "seven-stars"
        ]
      },
      "diamond-elite": {
        "value": "#752533",
        "type": "color",
        "description": "The color for to represent Diamond Elite status for Caesars Rewards.",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#752533",
          "type": "color",
          "description": "The color for to represent Diamond Elite status for Caesars Rewards.",
          "attributes": {
            "category": "color"
          }
        },
        "name": "rewards-diamond-elite",
        "path": [
          "core",
          "rewards",
          "diamond-elite"
        ]
      },
      "diamond-plus": {
        "value": "#3E4243",
        "type": "color",
        "description": "The color for to represent Diamond Plus status for Caesars Rewards.",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#3E4243",
          "type": "color",
          "description": "The color for to represent Diamond Plus status for Caesars Rewards.",
          "attributes": {
            "category": "color"
          }
        },
        "name": "rewards-diamond-plus",
        "path": [
          "core",
          "rewards",
          "diamond-plus"
        ]
      },
      "diamond": {
        "value": "#BCBCB7",
        "type": "color",
        "description": "The color for to represent Diamond status for Caesars Rewards.",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#BCBCB7",
          "type": "color",
          "description": "The color for to represent Diamond status for Caesars Rewards.",
          "attributes": {
            "category": "color"
          }
        },
        "name": "rewards-diamond",
        "path": [
          "core",
          "rewards",
          "diamond"
        ]
      },
      "platinum": {
        "value": "#838787",
        "type": "color",
        "description": "The color for to represent Platinum status for Caesars Rewards.",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#838787",
          "type": "color",
          "description": "The color for to represent Platinum status for Caesars Rewards.",
          "attributes": {
            "category": "color"
          }
        },
        "name": "rewards-platinum",
        "path": [
          "core",
          "rewards",
          "platinum"
        ]
      },
      "gold": {
        "value": "#A9975B",
        "type": "color",
        "description": "The color for to represent Gold status for Caesars Rewards.",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#A9975B",
          "type": "color",
          "description": "The color for to represent Gold status for Caesars Rewards.",
          "attributes": {
            "category": "color"
          }
        },
        "name": "rewards-gold",
        "path": [
          "core",
          "rewards",
          "gold"
        ]
      }
    },
    "neutrals": {
      "white": {
        "value": "#ffffff",
        "type": "color",
        "description": "White color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#ffffff",
          "type": "color",
          "description": "White color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "neutrals-white",
        "path": [
          "core",
          "neutrals",
          "white"
        ]
      },
      "black": {
        "value": "#000000",
        "type": "color",
        "description": "Black color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#000000",
          "type": "color",
          "description": "Black color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "neutrals-black",
        "path": [
          "core",
          "neutrals",
          "black"
        ]
      },
      "transparent": {
        "value": "rgba(255,255,255,0)",
        "type": "color",
        "description": "Transparent",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "rgba(255,255,255,0)",
          "type": "color",
          "description": "Transparent",
          "attributes": {
            "category": "color"
          }
        },
        "name": "neutrals-transparent",
        "path": [
          "core",
          "neutrals",
          "transparent"
        ]
      }
    },
    "amber": {
      "100": {
        "value": "#fffbf1",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#fffbf1",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "amber-100",
        "path": [
          "core",
          "amber",
          "100"
        ]
      },
      "200": {
        "value": "#fff6e1",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#fff6e1",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "amber-200",
        "path": [
          "core",
          "amber",
          "200"
        ]
      },
      "300": {
        "value": "#ffeaae",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#ffeaae",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "amber-300",
        "path": [
          "core",
          "amber",
          "300"
        ]
      },
      "400": {
        "value": "#ffd86d",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#ffd86d",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "amber-400",
        "path": [
          "core",
          "amber",
          "400"
        ]
      },
      "500": {
        "value": "#f6b211",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#f6b211",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "amber-500",
        "path": [
          "core",
          "amber",
          "500"
        ]
      },
      "600": {
        "value": "#b78100",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#b78100",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "amber-600",
        "path": [
          "core",
          "amber",
          "600"
        ]
      },
      "700": {
        "value": "#926700",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#926700",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "amber-700",
        "path": [
          "core",
          "amber",
          "700"
        ]
      },
      "800": {
        "value": "#755305",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#755305",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "amber-800",
        "path": [
          "core",
          "amber",
          "800"
        ]
      },
      "900": {
        "value": "#604409",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#604409",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "amber-900",
        "path": [
          "core",
          "amber",
          "900"
        ]
      },
      "1000": {
        "value": "#45310c",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#45310c",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "amber-1000",
        "path": [
          "core",
          "amber",
          "1000"
        ]
      },
      "1100": {
        "value": "#2e220b",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#2e220b",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "amber-1100",
        "path": [
          "core",
          "amber",
          "1100"
        ]
      },
      "1200": {
        "value": "#191206",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#191206",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "amber-1200",
        "path": [
          "core",
          "amber",
          "1200"
        ]
      }
    },
    "amber-dark": {
      "100": {
        "value": "#050301",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#050301",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "amber-dark-100",
        "path": [
          "core",
          "amber-dark",
          "100"
        ]
      },
      "200": {
        "value": "#171005",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#171005",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "amber-dark-200",
        "path": [
          "core",
          "amber-dark",
          "200"
        ]
      },
      "300": {
        "value": "#201807",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#201807",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "amber-dark-300",
        "path": [
          "core",
          "amber-dark",
          "300"
        ]
      },
      "400": {
        "value": "#37270A",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#37270A",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "amber-dark-400",
        "path": [
          "core",
          "amber-dark",
          "400"
        ]
      },
      "500": {
        "value": "#49330A",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#49330A",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "amber-dark-500",
        "path": [
          "core",
          "amber-dark",
          "500"
        ]
      },
      "600": {
        "value": "#875F00",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#875F00",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "amber-dark-600",
        "path": [
          "core",
          "amber-dark",
          "600"
        ]
      },
      "700": {
        "value": "#AF7A00",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#AF7A00",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "amber-dark-700",
        "path": [
          "core",
          "amber-dark",
          "700"
        ]
      },
      "800": {
        "value": "#c68b00",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#c68b00",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "amber-dark-800",
        "path": [
          "core",
          "amber-dark",
          "800"
        ]
      },
      "900": {
        "value": "#E09F00",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#E09F00",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "amber-dark-900",
        "path": [
          "core",
          "amber-dark",
          "900"
        ]
      },
      "1000": {
        "value": "#FEBA31",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#FEBA31",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "amber-dark-1000",
        "path": [
          "core",
          "amber-dark",
          "1000"
        ]
      },
      "1100": {
        "value": "#FFE6AA",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#FFE6AA",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "amber-dark-1100",
        "path": [
          "core",
          "amber-dark",
          "1100"
        ]
      },
      "1200": {
        "value": "#FFF2D7",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#FFF2D7",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "amber-dark-1200",
        "path": [
          "core",
          "amber-dark",
          "1200"
        ]
      }
    },
    "aqua": {
      "100": {
        "value": "#f8fdff",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#f8fdff",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "aqua-100",
        "path": [
          "core",
          "aqua",
          "100"
        ]
      },
      "200": {
        "value": "#e5f9ff",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#e5f9ff",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "aqua-200",
        "path": [
          "core",
          "aqua",
          "200"
        ]
      },
      "300": {
        "value": "#c4f3ff",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#c4f3ff",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "aqua-300",
        "path": [
          "core",
          "aqua",
          "300"
        ]
      },
      "400": {
        "value": "#99e8ff",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#99e8ff",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "aqua-400",
        "path": [
          "core",
          "aqua",
          "400"
        ]
      },
      "500": {
        "value": "#5ccaf6",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#5ccaf6",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "aqua-500",
        "path": [
          "core",
          "aqua",
          "500"
        ]
      },
      "600": {
        "value": "#3695b8",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#3695b8",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "aqua-600",
        "path": [
          "core",
          "aqua",
          "600"
        ]
      },
      "700": {
        "value": "#2d7794",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#2d7794",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "aqua-700",
        "path": [
          "core",
          "aqua",
          "700"
        ]
      },
      "800": {
        "value": "#275f76",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#275f76",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "aqua-800",
        "path": [
          "core",
          "aqua",
          "800"
        ]
      },
      "900": {
        "value": "#234e60",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#234e60",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "aqua-900",
        "path": [
          "core",
          "aqua",
          "900"
        ]
      },
      "1000": {
        "value": "#1d3845",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#1d3845",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "aqua-1000",
        "path": [
          "core",
          "aqua",
          "1000"
        ]
      },
      "1100": {
        "value": "#17262e",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#17262e",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "aqua-1100",
        "path": [
          "core",
          "aqua",
          "1100"
        ]
      },
      "1200": {
        "value": "#0e1416",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#0e1416",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "aqua-1200",
        "path": [
          "core",
          "aqua",
          "1200"
        ]
      }
    },
    "aqua-dark": {
      "100": {
        "value": "#030405",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#030405",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "aqua-dark-100",
        "path": [
          "core",
          "aqua-dark",
          "100"
        ]
      },
      "200": {
        "value": "#0c1214",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#0c1214",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "aqua-dark-200",
        "path": [
          "core",
          "aqua-dark",
          "200"
        ]
      },
      "300": {
        "value": "#121a1f",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#121a1f",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "aqua-dark-300",
        "path": [
          "core",
          "aqua-dark",
          "300"
        ]
      },
      "400": {
        "value": "#192c35",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#192c35",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "aqua-dark-400",
        "path": [
          "core",
          "aqua-dark",
          "400"
        ]
      },
      "500": {
        "value": "#1e3a47",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#1e3a47",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "aqua-dark-500",
        "path": [
          "core",
          "aqua-dark",
          "500"
        ]
      },
      "600": {
        "value": "#2a6d87",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#2a6d87",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "aqua-dark-600",
        "path": [
          "core",
          "aqua-dark",
          "600"
        ]
      },
      "700": {
        "value": "#348FB2",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#348FB2",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "aqua-dark-700",
        "path": [
          "core",
          "aqua-dark",
          "700"
        ]
      },
      "800": {
        "value": "#3da3ca",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#3da3ca",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "aqua-dark-800",
        "path": [
          "core",
          "aqua-dark",
          "800"
        ]
      },
      "900": {
        "value": "#48b7e1",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#48b7e1",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "aqua-dark-900",
        "path": [
          "core",
          "aqua-dark",
          "900"
        ]
      },
      "1000": {
        "value": "#68d2fe",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#68d2fe",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "aqua-dark-1000",
        "path": [
          "core",
          "aqua-dark",
          "1000"
        ]
      },
      "1100": {
        "value": "#b7f0ff",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#b7f0ff",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "aqua-dark-1100",
        "path": [
          "core",
          "aqua-dark",
          "1100"
        ]
      },
      "1200": {
        "value": "#d9f7ff",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#d9f7ff",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "aqua-dark-1200",
        "path": [
          "core",
          "aqua-dark",
          "1200"
        ]
      }
    },
    "blue": {
      "100": {
        "value": "#FAFCFF",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#FAFCFF",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "blue-100",
        "path": [
          "core",
          "blue",
          "100"
        ]
      },
      "200": {
        "value": "#F0F6FF",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#F0F6FF",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "blue-200",
        "path": [
          "core",
          "blue",
          "200"
        ]
      },
      "300": {
        "value": "#E1ECFF",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#E1ECFF",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "blue-300",
        "path": [
          "core",
          "blue",
          "300"
        ]
      },
      "400": {
        "value": "#C9DFFF",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#C9DFFF",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "blue-400",
        "path": [
          "core",
          "blue",
          "400"
        ]
      },
      "500": {
        "value": "#90C1FE",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#90C1FE",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "blue-500",
        "path": [
          "core",
          "blue",
          "500"
        ]
      },
      "600": {
        "value": "#0E8BFF",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#0E8BFF",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "blue-600",
        "path": [
          "core",
          "blue",
          "600"
        ]
      },
      "700": {
        "value": "#116DD6",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#116DD6",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "blue-700",
        "path": [
          "core",
          "blue",
          "700"
        ]
      },
      "800": {
        "value": "#1957AC",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#1957AC",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "blue-800",
        "path": [
          "core",
          "blue",
          "800"
        ]
      },
      "900": {
        "value": "#1C478B",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#1C478B",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "blue-900",
        "path": [
          "core",
          "blue",
          "900"
        ]
      },
      "1000": {
        "value": "#1B3461",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#1B3461",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "blue-1000",
        "path": [
          "core",
          "blue",
          "1000"
        ]
      },
      "1100": {
        "value": "#18233F",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#18233F",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "blue-1100",
        "path": [
          "core",
          "blue",
          "1100"
        ]
      },
      "1200": {
        "value": "#10121D",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#10121D",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "blue-1200",
        "path": [
          "core",
          "blue",
          "1200"
        ]
      }
    },
    "blue-dark": {
      "100": {
        "value": "#030407",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#030407",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "blue-dark-100",
        "path": [
          "core",
          "blue-dark",
          "100"
        ]
      },
      "200": {
        "value": "#0E101A",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#0E101A",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "blue-dark-200",
        "path": [
          "core",
          "blue-dark",
          "200"
        ]
      },
      "300": {
        "value": "#131828",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#131828",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "blue-dark-300",
        "path": [
          "core",
          "blue-dark",
          "300"
        ]
      },
      "400": {
        "value": "#19294C",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#19294C",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "blue-dark-400",
        "path": [
          "core",
          "blue-dark",
          "400"
        ]
      },
      "500": {
        "value": "#1B3665",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#1B3665",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "blue-dark-500",
        "path": [
          "core",
          "blue-dark",
          "500"
        ]
      },
      "600": {
        "value": "#1564C5",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#1564C5",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "blue-dark-600",
        "path": [
          "core",
          "blue-dark",
          "600"
        ]
      },
      "700": {
        "value": "#0982FC",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#0982FC",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "blue-dark-700",
        "path": [
          "core",
          "blue-dark",
          "700"
        ]
      },
      "800": {
        "value": "#259CFF",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#259CFF",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "blue-dark-800",
        "path": [
          "core",
          "blue-dark",
          "800"
        ]
      },
      "900": {
        "value": "#63AFFF",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#63AFFF",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "blue-dark-900",
        "path": [
          "core",
          "blue-dark",
          "900"
        ]
      },
      "1000": {
        "value": "#A1C9FE",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#A1C9FE",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "blue-dark-1000",
        "path": [
          "core",
          "blue-dark",
          "1000"
        ]
      },
      "1100": {
        "value": "#DCE9FF",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#DCE9FF",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "blue-dark-1100",
        "path": [
          "core",
          "blue-dark",
          "1100"
        ]
      },
      "1200": {
        "value": "#EBF2FF",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#EBF2FF",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "blue-dark-1200",
        "path": [
          "core",
          "blue-dark",
          "1200"
        ]
      }
    },
    "coral": {
      "100": {
        "value": "#fff9f9",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#fff9f9",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "coral-100",
        "path": [
          "core",
          "coral",
          "100"
        ]
      },
      "200": {
        "value": "#fff4f4",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#fff4f4",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "coral-200",
        "path": [
          "core",
          "coral",
          "200"
        ]
      },
      "300": {
        "value": "#ffe5e6",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#ffe5e6",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "coral-300",
        "path": [
          "core",
          "coral",
          "300"
        ]
      },
      "400": {
        "value": "#ffd1d2",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#ffd1d2",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "coral-400",
        "path": [
          "core",
          "coral",
          "400"
        ]
      },
      "500": {
        "value": "#ffa6ac",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#ffa6ac",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "coral-500",
        "path": [
          "core",
          "coral",
          "500"
        ]
      },
      "600": {
        "value": "#ff4063",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#ff4063",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "coral-600",
        "path": [
          "core",
          "coral",
          "600"
        ]
      },
      "700": {
        "value": "#d91849",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#d91849",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "coral-700",
        "path": [
          "core",
          "coral",
          "700"
        ]
      },
      "800": {
        "value": "#b10f39",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#b10f39",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "coral-800",
        "path": [
          "core",
          "coral",
          "800"
        ]
      },
      "900": {
        "value": "#91112f",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#91112f",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "coral-900",
        "path": [
          "core",
          "coral",
          "900"
        ]
      },
      "1000": {
        "value": "#671423",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#671423",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "coral-1000",
        "path": [
          "core",
          "coral",
          "1000"
        ]
      },
      "1100": {
        "value": "#441319",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#441319",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "coral-1100",
        "path": [
          "core",
          "coral",
          "1100"
        ]
      },
      "1200": {
        "value": "#210d0d",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#210d0d",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "coral-1200",
        "path": [
          "core",
          "coral",
          "1200"
        ]
      }
    },
    "coral-dark": {
      "100": {
        "value": "#070202",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#070202",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "coral-dark-100",
        "path": [
          "core",
          "coral-dark",
          "100"
        ]
      },
      "200": {
        "value": "#1F0D0D",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#1F0D0D",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "coral-dark-200",
        "path": [
          "core",
          "coral-dark",
          "200"
        ]
      },
      "300": {
        "value": "#2C1113",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#2C1113",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "coral-dark-300",
        "path": [
          "core",
          "coral-dark",
          "300"
        ]
      },
      "400": {
        "value": "#50151D",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#50151D",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "coral-dark-400",
        "path": [
          "core",
          "coral-dark",
          "400"
        ]
      },
      "500": {
        "value": "#6B1625",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#6B1625",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "coral-dark-500",
        "path": [
          "core",
          "coral-dark",
          "500"
        ]
      },
      "600": {
        "value": "#C81844",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#C81844",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "coral-dark-600",
        "path": [
          "core",
          "coral-dark",
          "600"
        ]
      },
      "700": {
        "value": "#F9315B",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#F9315B",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "coral-dark-700",
        "path": [
          "core",
          "coral-dark",
          "700"
        ]
      },
      "800": {
        "value": "#ff5e72",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#ff5e72",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "coral-dark-800",
        "path": [
          "core",
          "coral-dark",
          "800"
        ]
      },
      "900": {
        "value": "#FF8794",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#FF8794",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "coral-dark-900",
        "path": [
          "core",
          "coral-dark",
          "900"
        ]
      },
      "1000": {
        "value": "#FFB2B7",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#FFB2B7",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "coral-dark-1000",
        "path": [
          "core",
          "coral-dark",
          "1000"
        ]
      },
      "1100": {
        "value": "#FFE1E2",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#FFE1E2",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "coral-dark-1100",
        "path": [
          "core",
          "coral-dark",
          "1100"
        ]
      },
      "1200": {
        "value": "#FFEFF0",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#FFEFF0",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "coral-dark-1200",
        "path": [
          "core",
          "coral-dark",
          "1200"
        ]
      }
    },
    "grass": {
      "100": {
        "value": "#f7fdef",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#f7fdef",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "grass-100",
        "path": [
          "core",
          "grass",
          "100"
        ]
      },
      "200": {
        "value": "#e8fcd1",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#e8fcd1",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "grass-200",
        "path": [
          "core",
          "grass",
          "200"
        ]
      },
      "300": {
        "value": "#cef8a5",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#cef8a5",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "grass-300",
        "path": [
          "core",
          "grass",
          "300"
        ]
      },
      "400": {
        "value": "#b3ed7d",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#b3ed7d",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "grass-400",
        "path": [
          "core",
          "grass",
          "400"
        ]
      },
      "500": {
        "value": "#8ed054",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#8ed054",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "grass-500",
        "path": [
          "core",
          "grass",
          "500"
        ]
      },
      "600": {
        "value": "#659937",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#659937",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "grass-600",
        "path": [
          "core",
          "grass",
          "600"
        ]
      },
      "700": {
        "value": "#517a2d",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#517a2d",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "grass-700",
        "path": [
          "core",
          "grass",
          "700"
        ]
      },
      "800": {
        "value": "#426125",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#426125",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "grass-800",
        "path": [
          "core",
          "grass",
          "800"
        ]
      },
      "900": {
        "value": "#375020",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#375020",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "grass-900",
        "path": [
          "core",
          "grass",
          "900"
        ]
      },
      "1000": {
        "value": "#29391a",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#29391a",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "grass-1000",
        "path": [
          "core",
          "grass",
          "1000"
        ]
      },
      "1100": {
        "value": "#1d2714",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#1d2714",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "grass-1100",
        "path": [
          "core",
          "grass",
          "1100"
        ]
      },
      "1200": {
        "value": "#11140b",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#11140b",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "grass-1200",
        "path": [
          "core",
          "grass",
          "1200"
        ]
      }
    },
    "grass-dark": {
      "100": {
        "value": "#030402",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#030402",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "grass-dark-100",
        "path": [
          "core",
          "grass-dark",
          "100"
        ]
      },
      "200": {
        "value": "#0f1309",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#0f1309",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "grass-dark-200",
        "path": [
          "core",
          "grass-dark",
          "200"
        ]
      },
      "300": {
        "value": "#161b0e",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#161b0e",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "grass-dark-300",
        "path": [
          "core",
          "grass-dark",
          "300"
        ]
      },
      "400": {
        "value": "#212e16",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#212e16",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "grass-dark-400",
        "path": [
          "core",
          "grass-dark",
          "400"
        ]
      },
      "500": {
        "value": "#2a3c19",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#2a3c19",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "grass-dark-500",
        "path": [
          "core",
          "grass-dark",
          "500"
        ]
      },
      "600": {
        "value": "#497127",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#497127",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "grass-dark-600",
        "path": [
          "core",
          "grass-dark",
          "600"
        ]
      },
      "700": {
        "value": "#5e9132",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#5e9132",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "grass-dark-700",
        "path": [
          "core",
          "grass-dark",
          "700"
        ]
      },
      "800": {
        "value": "#6da53c",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#6da53c",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "grass-dark-800",
        "path": [
          "core",
          "grass-dark",
          "800"
        ]
      },
      "900": {
        "value": "#7cbc45",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#7cbc45",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "grass-dark-900",
        "path": [
          "core",
          "grass-dark",
          "900"
        ]
      },
      "1000": {
        "value": "#96d85c",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#96d85c",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "grass-dark-1000",
        "path": [
          "core",
          "grass-dark",
          "1000"
        ]
      },
      "1100": {
        "value": "#cbf5a3",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#cbf5a3",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "grass-dark-1100",
        "path": [
          "core",
          "grass-dark",
          "1100"
        ]
      },
      "1200": {
        "value": "#e6facf",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#e6facf",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "grass-dark-1200",
        "path": [
          "core",
          "grass-dark",
          "1200"
        ]
      }
    },
    "gray": {
      "100": {
        "value": "#fdfaf4",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#fdfaf4",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "gray-100",
        "path": [
          "core",
          "gray",
          "100"
        ]
      },
      "200": {
        "value": "#faf6f0",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#faf6f0",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "gray-200",
        "path": [
          "core",
          "gray",
          "200"
        ]
      },
      "300": {
        "value": "#efebe5",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#efebe5",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "gray-300",
        "path": [
          "core",
          "gray",
          "300"
        ]
      },
      "400": {
        "value": "#e0dcd6",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#e0dcd6",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "gray-400",
        "path": [
          "core",
          "gray",
          "400"
        ]
      },
      "500": {
        "value": "#c0bdb7",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#c0bdb7",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "gray-500",
        "path": [
          "core",
          "gray",
          "500"
        ]
      },
      "600": {
        "value": "#8d8a87",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#8d8a87",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "gray-600",
        "path": [
          "core",
          "gray",
          "600"
        ]
      },
      "700": {
        "value": "#706e6b",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#706e6b",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "gray-700",
        "path": [
          "core",
          "gray",
          "700"
        ]
      },
      "800": {
        "value": "#5a5956",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#5a5956",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "gray-800",
        "path": [
          "core",
          "gray",
          "800"
        ]
      },
      "900": {
        "value": "#4a4847",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#4a4847",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "gray-900",
        "path": [
          "core",
          "gray",
          "900"
        ]
      },
      "1000": {
        "value": "#353533",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#353533",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "gray-1000",
        "path": [
          "core",
          "gray",
          "1000"
        ]
      },
      "1100": {
        "value": "#242423",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#242423",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "gray-1100",
        "path": [
          "core",
          "gray",
          "1100"
        ]
      },
      "1200": {
        "value": "#131312",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#131312",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "gray-1200",
        "path": [
          "core",
          "gray",
          "1200"
        ]
      }
    },
    "gray-dark": {
      "100": {
        "value": "#030303",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#030303",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "gray-dark-100",
        "path": [
          "core",
          "gray-dark",
          "100"
        ]
      },
      "200": {
        "value": "#111110",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#111110",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "gray-dark-200",
        "path": [
          "core",
          "gray-dark",
          "200"
        ]
      },
      "300": {
        "value": "#1a1919",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#1a1919",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "gray-dark-300",
        "path": [
          "core",
          "gray-dark",
          "300"
        ]
      },
      "400": {
        "value": "#252423",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#252423",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "gray-dark-400",
        "path": [
          "core",
          "gray-dark",
          "400"
        ]
      },
      "500": {
        "value": "#2b2a29",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#2b2a29",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "gray-dark-500",
        "path": [
          "core",
          "gray-dark",
          "500"
        ]
      },
      "600": {
        "value": "#383736",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#383736",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "gray-dark-600",
        "path": [
          "core",
          "gray-dark",
          "600"
        ]
      },
      "700": {
        "value": "#686663",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#686663",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "gray-dark-700",
        "path": [
          "core",
          "gray-dark",
          "700"
        ]
      },
      "800": {
        "value": "#868380",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#868380",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "gray-dark-800",
        "path": [
          "core",
          "gray-dark",
          "800"
        ]
      },
      "900": {
        "value": "#aeaaa6",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#aeaaa6",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "gray-dark-900",
        "path": [
          "core",
          "gray-dark",
          "900"
        ]
      },
      "1000": {
        "value": "#c9c5c0",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#c9c5c0",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "gray-dark-1000",
        "path": [
          "core",
          "gray-dark",
          "1000"
        ]
      },
      "1100": {
        "value": "#ebe7e1",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#ebe7e1",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "gray-dark-1100",
        "path": [
          "core",
          "gray-dark",
          "1100"
        ]
      },
      "1200": {
        "value": "#f6f2ed",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#f6f2ed",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "gray-dark-1200",
        "path": [
          "core",
          "gray-dark",
          "1200"
        ]
      }
    },
    "green": {
      "100": {
        "value": "#f5fdf3",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#f5fdf3",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "green-100",
        "path": [
          "core",
          "green",
          "100"
        ]
      },
      "200": {
        "value": "#edf8eb",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#edf8eb",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "green-200",
        "path": [
          "core",
          "green",
          "200"
        ]
      },
      "300": {
        "value": "#dfefdd",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#dfefdd",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "green-300",
        "path": [
          "core",
          "green",
          "300"
        ]
      },
      "400": {
        "value": "#cee3cd",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#cee3cd",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "green-400",
        "path": [
          "core",
          "green",
          "400"
        ]
      },
      "500": {
        "value": "#a0c7a5",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#a0c7a5",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "green-500",
        "path": [
          "core",
          "green",
          "500"
        ]
      },
      "600": {
        "value": "#51996c",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#51996c",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "green-600",
        "path": [
          "core",
          "green",
          "600"
        ]
      },
      "700": {
        "value": "#0F7F4F",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#0F7F4F",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "green-700",
        "path": [
          "core",
          "green",
          "700"
        ]
      },
      "800": {
        "value": "#00663b",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#00663b",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "green-800",
        "path": [
          "core",
          "green",
          "800"
        ]
      },
      "900": {
        "value": "#095431",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#095431",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "green-900",
        "path": [
          "core",
          "green",
          "900"
        ]
      },
      "1000": {
        "value": "#0f3c25",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#0f3c25",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "green-1000",
        "path": [
          "core",
          "green",
          "1000"
        ]
      },
      "1100": {
        "value": "#0f291a",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#0f291a",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "green-1100",
        "path": [
          "core",
          "green",
          "1100"
        ]
      },
      "1200": {
        "value": "#09150e",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#09150e",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "green-1200",
        "path": [
          "core",
          "green",
          "1200"
        ]
      }
    },
    "green-dark": {
      "100": {
        "value": "#020402",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#020402",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "green-dark-100",
        "path": [
          "core",
          "green-dark",
          "100"
        ]
      },
      "200": {
        "value": "#0A140D",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#0A140D",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "green-dark-200",
        "path": [
          "core",
          "green-dark",
          "200"
        ]
      },
      "300": {
        "value": "#0F1C13",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#0F1C13",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "green-dark-300",
        "path": [
          "core",
          "green-dark",
          "300"
        ]
      },
      "400": {
        "value": "#12301E",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#12301E",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "green-dark-400",
        "path": [
          "core",
          "green-dark",
          "400"
        ]
      },
      "500": {
        "value": "#123F26",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#123F26",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "green-dark-500",
        "path": [
          "core",
          "green-dark",
          "500"
        ]
      },
      "600": {
        "value": "#007642",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#007642",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "green-dark-600",
        "path": [
          "core",
          "green-dark",
          "600"
        ]
      },
      "700": {
        "value": "#009E57",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#009E57",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "green-dark-700",
        "path": [
          "core",
          "green-dark",
          "700"
        ]
      },
      "800": {
        "value": "#00ac5f",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#00ac5f",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "green-dark-800",
        "path": [
          "core",
          "green-dark",
          "800"
        ]
      },
      "900": {
        "value": "#00C46E",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#00C46E",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "green-dark-900",
        "path": [
          "core",
          "green-dark",
          "900"
        ]
      },
      "1000": {
        "value": "#00E285",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#00E285",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "green-dark-1000",
        "path": [
          "core",
          "green-dark",
          "1000"
        ]
      },
      "1100": {
        "value": "#8DFFBA",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#8DFFBA",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "green-dark-1100",
        "path": [
          "core",
          "green-dark",
          "1100"
        ]
      },
      "1200": {
        "value": "#CCFFDE",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#CCFFDE",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "green-dark-1200",
        "path": [
          "core",
          "green-dark",
          "1200"
        ]
      }
    },
    "gold": {
      "100": {
        "value": "#fdfbf8",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#fdfbf8",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "gold-100",
        "path": [
          "core",
          "gold",
          "100"
        ]
      },
      "200": {
        "value": "#faf4ea",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#faf4ea",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "gold-200",
        "path": [
          "core",
          "gold",
          "200"
        ]
      },
      "300": {
        "value": "#f4e9d4",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#f4e9d4",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "gold-300",
        "path": [
          "core",
          "gold",
          "300"
        ]
      },
      "400": {
        "value": "#ecdab8",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#ecdab8",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "gold-400",
        "path": [
          "core",
          "gold",
          "400"
        ]
      },
      "500": {
        "value": "#d7bb7e",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#d7bb7e",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "gold-500",
        "path": [
          "core",
          "gold",
          "500"
        ]
      },
      "600": {
        "value": "#a0875b",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#a0875b",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "gold-600",
        "path": [
          "core",
          "gold",
          "600"
        ]
      },
      "700": {
        "value": "#806c4b",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#806c4b",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "gold-700",
        "path": [
          "core",
          "gold",
          "700"
        ]
      },
      "800": {
        "value": "#66573e",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#66573e",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "gold-800",
        "path": [
          "core",
          "gold",
          "800"
        ]
      },
      "900": {
        "value": "#544733",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#544733",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "gold-900",
        "path": [
          "core",
          "gold",
          "900"
        ]
      },
      "1000": {
        "value": "#3c3426",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#3c3426",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "gold-1000",
        "path": [
          "core",
          "gold",
          "1000"
        ]
      },
      "1100": {
        "value": "#29231b",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#29231b",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "gold-1100",
        "path": [
          "core",
          "gold",
          "1100"
        ]
      },
      "1200": {
        "value": "#15120e",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#15120e",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "gold-1200",
        "path": [
          "core",
          "gold",
          "1200"
        ]
      }
    },
    "gold-dark": {
      "100": {
        "value": "#040302",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#040302",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "gold-dark-100",
        "path": [
          "core",
          "gold-dark",
          "100"
        ]
      },
      "200": {
        "value": "#15120D",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#15120D",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "gold-dark-200",
        "path": [
          "core",
          "gold-dark",
          "200"
        ]
      },
      "300": {
        "value": "#1C1812",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#1C1812",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "gold-dark-300",
        "path": [
          "core",
          "gold-dark",
          "300"
        ]
      },
      "400": {
        "value": "#31291D",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#31291D",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "gold-dark-400",
        "path": [
          "core",
          "gold-dark",
          "400"
        ]
      },
      "500": {
        "value": "#403524",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#403524",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "gold-dark-500",
        "path": [
          "core",
          "gold-dark",
          "500"
        ]
      },
      "600": {
        "value": "#78633F",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#78633F",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "gold-dark-600",
        "path": [
          "core",
          "gold-dark",
          "600"
        ]
      },
      "700": {
        "value": "#9B8055",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#9B8055",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "gold-dark-700",
        "path": [
          "core",
          "gold-dark",
          "700"
        ]
      },
      "800": {
        "value": "#ad9366",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#ad9366",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "gold-dark-800",
        "path": [
          "core",
          "gold-dark",
          "800"
        ]
      },
      "900": {
        "value": "#C3A77C",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#C3A77C",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "gold-dark-900",
        "path": [
          "core",
          "gold-dark",
          "900"
        ]
      },
      "1000": {
        "value": "#DAC3A1",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#DAC3A1",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "gold-dark-1000",
        "path": [
          "core",
          "gold-dark",
          "1000"
        ]
      },
      "1100": {
        "value": "#F1E6D8",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#F1E6D8",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "gold-dark-1100",
        "path": [
          "core",
          "gold-dark",
          "1100"
        ]
      },
      "1200": {
        "value": "#F8F2EB",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#F8F2EB",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "gold-dark-1200",
        "path": [
          "core",
          "gold-dark",
          "1200"
        ]
      }
    },
    "maroon": {
      "100": {
        "value": "#fefafa",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#fefafa",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "maroon-100",
        "path": [
          "core",
          "maroon",
          "100"
        ]
      },
      "200": {
        "value": "#fef3f2",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#fef3f2",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "maroon-200",
        "path": [
          "core",
          "maroon",
          "200"
        ]
      },
      "300": {
        "value": "#fde5e4",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#fde5e4",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "maroon-300",
        "path": [
          "core",
          "maroon",
          "300"
        ]
      },
      "400": {
        "value": "#fcd3d0",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#fcd3d0",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "maroon-400",
        "path": [
          "core",
          "maroon",
          "400"
        ]
      },
      "500": {
        "value": "#f5aba7",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#f5aba7",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "maroon-500",
        "path": [
          "core",
          "maroon",
          "500"
        ]
      },
      "600": {
        "value": "#db6766",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#db6766",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "maroon-600",
        "path": [
          "core",
          "maroon",
          "600"
        ]
      },
      "700": {
        "value": "#c24247",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#c24247",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "maroon-700",
        "path": [
          "core",
          "maroon",
          "700"
        ]
      },
      "800": {
        "value": "#a52a34",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#a52a34",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "maroon-800",
        "path": [
          "core",
          "maroon",
          "800"
        ]
      },
      "900": {
        "value": "#8b1f29",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#8b1f29",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "maroon-900",
        "path": [
          "core",
          "maroon",
          "900"
        ]
      },
      "1000": {
        "value": "#65181d",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#65181d",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "maroon-1000",
        "path": [
          "core",
          "maroon",
          "1000"
        ]
      },
      "1100": {
        "value": "#431415",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#431415",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "maroon-1100",
        "path": [
          "core",
          "maroon",
          "1100"
        ]
      },
      "1200": {
        "value": "#210d0b",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#210d0b",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "maroon-1200",
        "path": [
          "core",
          "maroon",
          "1200"
        ]
      }
    },
    "maroon-dark": {
      "100": {
        "value": "#070202",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#070202",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "maroon-dark-100",
        "path": [
          "core",
          "maroon-dark",
          "100"
        ]
      },
      "200": {
        "value": "#1E0C09",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#1E0C09",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "maroon-dark-200",
        "path": [
          "core",
          "maroon-dark",
          "200"
        ]
      },
      "300": {
        "value": "#2E110F",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#2E110F",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "maroon-dark-300",
        "path": [
          "core",
          "maroon-dark",
          "300"
        ]
      },
      "400": {
        "value": "#521418",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#521418",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "maroon-dark-400",
        "path": [
          "core",
          "maroon-dark",
          "400"
        ]
      },
      "500": {
        "value": "#6C161E",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#6C161E",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "maroon-dark-500",
        "path": [
          "core",
          "maroon-dark",
          "500"
        ]
      },
      "600": {
        "value": "#B9363F",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#B9363F",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "maroon-dark-600",
        "path": [
          "core",
          "maroon-dark",
          "600"
        ]
      },
      "700": {
        "value": "#D65E5E",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#D65E5E",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "maroon-dark-700",
        "path": [
          "core",
          "maroon-dark",
          "700"
        ]
      },
      "800": {
        "value": "#e27673",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#e27673",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "maroon-dark-800",
        "path": [
          "core",
          "maroon-dark",
          "800"
        ]
      },
      "900": {
        "value": "#EE928F",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#EE928F",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "maroon-dark-900",
        "path": [
          "core",
          "maroon-dark",
          "900"
        ]
      },
      "1000": {
        "value": "#F7B6B2",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#F7B6B2",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "maroon-dark-1000",
        "path": [
          "core",
          "maroon-dark",
          "1000"
        ]
      },
      "1100": {
        "value": "#FDE2E0",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#FDE2E0",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "maroon-dark-1100",
        "path": [
          "core",
          "maroon-dark",
          "1100"
        ]
      },
      "1200": {
        "value": "#FEF0EF",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#FEF0EF",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "maroon-dark-1200",
        "path": [
          "core",
          "maroon-dark",
          "1200"
        ]
      }
    },
    "orange": {
      "100": {
        "value": "#fffaf6",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#fffaf6",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "orange-100",
        "path": [
          "core",
          "orange",
          "100"
        ]
      },
      "200": {
        "value": "#fff4ed",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#fff4ed",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "orange-200",
        "path": [
          "core",
          "orange",
          "200"
        ]
      },
      "300": {
        "value": "#ffe7d5",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#ffe7d5",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "orange-300",
        "path": [
          "core",
          "orange",
          "300"
        ]
      },
      "400": {
        "value": "#ffd4b3",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#ffd4b3",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "orange-400",
        "path": [
          "core",
          "orange",
          "400"
        ]
      },
      "500": {
        "value": "#ffaa6f",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#ffaa6f",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "orange-500",
        "path": [
          "core",
          "orange",
          "500"
        ]
      },
      "600": {
        "value": "#ee5c00",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#ee5c00",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "orange-600",
        "path": [
          "core",
          "orange",
          "600"
        ]
      },
      "700": {
        "value": "#c24600",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#c24600",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "orange-700",
        "path": [
          "core",
          "orange",
          "700"
        ]
      },
      "800": {
        "value": "#9d3800",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#9d3800",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "orange-800",
        "path": [
          "core",
          "orange",
          "800"
        ]
      },
      "900": {
        "value": "#802f00",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#802f00",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "orange-900",
        "path": [
          "core",
          "orange",
          "900"
        ]
      },
      "1000": {
        "value": "#5c2402",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#5c2402",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "orange-1000",
        "path": [
          "core",
          "orange",
          "1000"
        ]
      },
      "1100": {
        "value": "#441d04",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#441d04",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "orange-1100",
        "path": [
          "core",
          "orange",
          "1100"
        ]
      },
      "1200": {
        "value": "#3d1a04",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#3d1a04",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "orange-1200",
        "path": [
          "core",
          "orange",
          "1200"
        ]
      }
    },
    "orange-dark": {
      "100": {
        "value": "#060301",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#060301",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "orange-dark-100",
        "path": [
          "core",
          "orange-dark",
          "100"
        ]
      },
      "200": {
        "value": "#1D0F06",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#1D0F06",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "orange-dark-200",
        "path": [
          "core",
          "orange-dark",
          "200"
        ]
      },
      "300": {
        "value": "#271409",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#271409",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "orange-dark-300",
        "path": [
          "core",
          "orange-dark",
          "300"
        ]
      },
      "400": {
        "value": "#461F0F",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#461F0F",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "orange-dark-400",
        "path": [
          "core",
          "orange-dark",
          "400"
        ]
      },
      "500": {
        "value": "#5D2711",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#5D2711",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "orange-dark-500",
        "path": [
          "core",
          "orange-dark",
          "500"
        ]
      },
      "600": {
        "value": "#AC471D",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#AC471D",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "orange-dark-600",
        "path": [
          "core",
          "orange-dark",
          "600"
        ]
      },
      "700": {
        "value": "#D66130",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#D66130",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "orange-dark-700",
        "path": [
          "core",
          "orange-dark",
          "700"
        ]
      },
      "800": {
        "value": "#e2794d",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#e2794d",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "orange-dark-800",
        "path": [
          "core",
          "orange-dark",
          "800"
        ]
      },
      "900": {
        "value": "#F79064",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#F79064",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "orange-dark-900",
        "path": [
          "core",
          "orange-dark",
          "900"
        ]
      },
      "1000": {
        "value": "#FFB594",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#FFB594",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "orange-dark-1000",
        "path": [
          "core",
          "orange-dark",
          "1000"
        ]
      },
      "1100": {
        "value": "#FFE2D5",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#FFE2D5",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "orange-dark-1100",
        "path": [
          "core",
          "orange-dark",
          "1100"
        ]
      },
      "1200": {
        "value": "#FFF0EA",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#FFF0EA",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "orange-dark-1200",
        "path": [
          "core",
          "orange-dark",
          "1200"
        ]
      }
    },
    "pink": {
      "100": {
        "value": "#FFFBFE",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#FFFBFE",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "pink-100",
        "path": [
          "core",
          "pink",
          "100"
        ]
      },
      "200": {
        "value": "#FFF3FC",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#FFF3FC",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "pink-200",
        "path": [
          "core",
          "pink",
          "200"
        ]
      },
      "300": {
        "value": "#FFE3F7",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#FFE3F7",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "pink-300",
        "path": [
          "core",
          "pink",
          "300"
        ]
      },
      "400": {
        "value": "#FFCFF2",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#FFCFF2",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "pink-400",
        "path": [
          "core",
          "pink",
          "400"
        ]
      },
      "500": {
        "value": "#FF86DF",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#FF86DF",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "pink-500",
        "path": [
          "core",
          "pink",
          "500"
        ]
      },
      "600": {
        "value": "#FB21CB",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#FB21CB",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "pink-600",
        "path": [
          "core",
          "pink",
          "600"
        ]
      },
      "700": {
        "value": "#C725A1",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#C725A1",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "pink-700",
        "path": [
          "core",
          "pink",
          "700"
        ]
      },
      "800": {
        "value": "#9E2480",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#9E2480",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "pink-800",
        "path": [
          "core",
          "pink",
          "800"
        ]
      },
      "900": {
        "value": "#7F2267",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#7F2267",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "pink-900",
        "path": [
          "core",
          "pink",
          "900"
        ]
      },
      "1000": {
        "value": "#5A1D49",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#5A1D49",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "pink-1000",
        "path": [
          "core",
          "pink",
          "1000"
        ]
      },
      "1100": {
        "value": "#3A1730",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#3A1730",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "pink-1100",
        "path": [
          "core",
          "pink",
          "1100"
        ]
      },
      "1200": {
        "value": "#1D0F19",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#1D0F19",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "pink-1200",
        "path": [
          "core",
          "pink",
          "1200"
        ]
      }
    },
    "pink-dark": {
      "100": {
        "value": "#060204",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#060204",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "pink-dark-100",
        "path": [
          "core",
          "pink-dark",
          "100"
        ]
      },
      "200": {
        "value": "#1C0E17",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#1C0E17",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "pink-dark-200",
        "path": [
          "core",
          "pink-dark",
          "200"
        ]
      },
      "300": {
        "value": "#281220",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#281220",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "pink-dark-300",
        "path": [
          "core",
          "pink-dark",
          "300"
        ]
      },
      "400": {
        "value": "#4A1738",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#4A1738",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "pink-dark-400",
        "path": [
          "core",
          "pink-dark",
          "400"
        ]
      },
      "500": {
        "value": "#621A4A",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#621A4A",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "pink-dark-500",
        "path": [
          "core",
          "pink-dark",
          "500"
        ]
      },
      "600": {
        "value": "#B6288B",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#B6288B",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "pink-dark-600",
        "path": [
          "core",
          "pink-dark",
          "600"
        ]
      },
      "700": {
        "value": "#E143AE",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#E143AE",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "pink-dark-700",
        "path": [
          "core",
          "pink-dark",
          "700"
        ]
      },
      "800": {
        "value": "#ea65bc",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#ea65bc",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "pink-dark-800",
        "path": [
          "core",
          "pink-dark",
          "800"
        ]
      },
      "900": {
        "value": "#FE7FCF",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#FE7FCF",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "pink-dark-900",
        "path": [
          "core",
          "pink-dark",
          "900"
        ]
      },
      "1000": {
        "value": "#FFACE1",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#FFACE1",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "pink-dark-1000",
        "path": [
          "core",
          "pink-dark",
          "1000"
        ]
      },
      "1100": {
        "value": "#FFDFF3",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#FFDFF3",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "pink-dark-1100",
        "path": [
          "core",
          "pink-dark",
          "1100"
        ]
      },
      "1200": {
        "value": "#FFEEF8",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#FFEEF8",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "pink-dark-1200",
        "path": [
          "core",
          "pink-dark",
          "1200"
        ]
      }
    },
    "purple": {
      "100": {
        "value": "#fefaff",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#fefaff",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "purple-100",
        "path": [
          "core",
          "purple",
          "100"
        ]
      },
      "200": {
        "value": "#fdf2fe",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#fdf2fe",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "purple-200",
        "path": [
          "core",
          "purple",
          "200"
        ]
      },
      "300": {
        "value": "#fae3fe",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#fae3fe",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "purple-300",
        "path": [
          "core",
          "purple",
          "300"
        ]
      },
      "400": {
        "value": "#f7d0ff",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#f7d0ff",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "purple-400",
        "path": [
          "core",
          "purple",
          "400"
        ]
      },
      "500": {
        "value": "#e78dfd",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#e78dfd",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "purple-500",
        "path": [
          "core",
          "purple",
          "500"
        ]
      },
      "600": {
        "value": "#d051f4",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#d051f4",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "purple-600",
        "path": [
          "core",
          "purple",
          "600"
        ]
      },
      "700": {
        "value": "#b615e1",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#b615e1",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "purple-700",
        "path": [
          "core",
          "purple",
          "700"
        ]
      },
      "800": {
        "value": "#9400bd",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#9400bd",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "purple-800",
        "path": [
          "core",
          "purple",
          "800"
        ]
      },
      "900": {
        "value": "#7b009d",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#7b009d",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "purple-900",
        "path": [
          "core",
          "purple",
          "900"
        ]
      },
      "1000": {
        "value": "#5c0075",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#5c0075",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "purple-1000",
        "path": [
          "core",
          "purple",
          "1000"
        ]
      },
      "1100": {
        "value": "#3e0a4c",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#3e0a4c",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "purple-1100",
        "path": [
          "core",
          "purple",
          "1100"
        ]
      },
      "1200": {
        "value": "#1e0c21",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#1e0c21",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "purple-1200",
        "path": [
          "core",
          "purple",
          "1200"
        ]
      }
    },
    "purple-dark": {
      "100": {
        "value": "#070208",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#070208",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "purple-dark-100",
        "path": [
          "core",
          "purple-dark",
          "100"
        ]
      },
      "200": {
        "value": "#1b0a1e",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#1b0a1e",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "purple-dark-200",
        "path": [
          "core",
          "purple-dark",
          "200"
        ]
      },
      "300": {
        "value": "#290d30",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#290d30",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "purple-dark-300",
        "path": [
          "core",
          "purple-dark",
          "300"
        ]
      },
      "400": {
        "value": "#49065c",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#49065c",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "purple-dark-400",
        "path": [
          "core",
          "purple-dark",
          "400"
        ]
      },
      "500": {
        "value": "#5f0079",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#5f0079",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "purple-dark-500",
        "path": [
          "core",
          "purple-dark",
          "500"
        ]
      },
      "600": {
        "value": "#a900d4",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#a900d4",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "purple-dark-600",
        "path": [
          "core",
          "purple-dark",
          "600"
        ]
      },
      "700": {
        "value": "#ca44f0",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#ca44f0",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "purple-dark-700",
        "path": [
          "core",
          "purple-dark",
          "700"
        ]
      },
      "800": {
        "value": "#d968f8",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#d968f8",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "purple-dark-800",
        "path": [
          "core",
          "purple-dark",
          "800"
        ]
      },
      "900": {
        "value": "#e485fd",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#e485fd",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "purple-dark-900",
        "path": [
          "core",
          "purple-dark",
          "900"
        ]
      },
      "1000": {
        "value": "#f1afff",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#f1afff",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "purple-dark-1000",
        "path": [
          "core",
          "purple-dark",
          "1000"
        ]
      },
      "1100": {
        "value": "#fadfff",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#fadfff",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "purple-dark-1100",
        "path": [
          "core",
          "purple-dark",
          "1100"
        ]
      },
      "1200": {
        "value": "#fcedfe",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#fcedfe",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "purple-dark-1200",
        "path": [
          "core",
          "purple-dark",
          "1200"
        ]
      }
    },
    "red": {
      "100": {
        "value": "#FFFBFA",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#FFFBFA",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "red-100",
        "path": [
          "core",
          "red",
          "100"
        ]
      },
      "200": {
        "value": "#FFF4F1",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#FFF4F1",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "red-200",
        "path": [
          "core",
          "red",
          "200"
        ]
      },
      "300": {
        "value": "#FFE6DE",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#FFE6DE",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "red-300",
        "path": [
          "core",
          "red",
          "300"
        ]
      },
      "400": {
        "value": "#FED3C6",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#FED3C6",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "red-400",
        "path": [
          "core",
          "red",
          "400"
        ]
      },
      "500": {
        "value": "#FD9579",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#FD9579",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "red-500",
        "path": [
          "core",
          "red",
          "500"
        ]
      },
      "600": {
        "value": "#F7522E",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#F7522E",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "red-600",
        "path": [
          "core",
          "red",
          "600"
        ]
      },
      "700": {
        "value": "#D82413",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#D82413",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "red-700",
        "path": [
          "core",
          "red",
          "700"
        ]
      },
      "800": {
        "value": "#B60000",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#B60000",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "red-800",
        "path": [
          "core",
          "red",
          "800"
        ]
      },
      "900": {
        "value": "#970000",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#970000",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "red-900",
        "path": [
          "core",
          "red",
          "900"
        ]
      },
      "1000": {
        "value": "#5F200B",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#5F200B",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "red-1000",
        "path": [
          "core",
          "red",
          "1000"
        ]
      },
      "1100": {
        "value": "#41160F",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#41160F",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "red-1100",
        "path": [
          "core",
          "red",
          "1100"
        ]
      },
      "1200": {
        "value": "#220E06",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#220E06",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "red-1200",
        "path": [
          "core",
          "red",
          "1200"
        ]
      }
    },
    "red-dark": {
      "100": {
        "value": "#090200",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#090200",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "red-dark-100",
        "path": [
          "core",
          "red-dark",
          "100"
        ]
      },
      "200": {
        "value": "#220A00",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#220A00",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "red-dark-200",
        "path": [
          "core",
          "red-dark",
          "200"
        ]
      },
      "300": {
        "value": "#330D00",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#330D00",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "red-dark-300",
        "path": [
          "core",
          "red-dark",
          "300"
        ]
      },
      "400": {
        "value": "#5A0600",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#5A0600",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "red-dark-400",
        "path": [
          "core",
          "red-dark",
          "400"
        ]
      },
      "500": {
        "value": "#760000",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#760000",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "red-dark-500",
        "path": [
          "core",
          "red-dark",
          "500"
        ]
      },
      "600": {
        "value": "#CD1007",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#CD1007",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "red-dark-600",
        "path": [
          "core",
          "red-dark",
          "600"
        ]
      },
      "700": {
        "value": "#EB4C31",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#EB4C31",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "red-dark-700",
        "path": [
          "core",
          "red-dark",
          "700"
        ]
      },
      "800": {
        "value": "#f8694a",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#f8694a",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "red-dark-800",
        "path": [
          "core",
          "red-dark",
          "800"
        ]
      },
      "900": {
        "value": "#FF8A6E",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#FF8A6E",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "red-dark-900",
        "path": [
          "core",
          "red-dark",
          "900"
        ]
      },
      "1000": {
        "value": "#FFB4A0",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#FFB4A0",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "red-dark-1000",
        "path": [
          "core",
          "red-dark",
          "1000"
        ]
      },
      "1100": {
        "value": "#FFE2DA",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#FFE2DA",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "red-dark-1100",
        "path": [
          "core",
          "red-dark",
          "1100"
        ]
      },
      "1200": {
        "value": "#FFF0EC",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#FFF0EC",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "red-dark-1200",
        "path": [
          "core",
          "red-dark",
          "1200"
        ]
      }
    },
    "silver": {
      "100": {
        "value": "#fbfbfb",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#fbfbfb",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "silver-100",
        "path": [
          "core",
          "silver",
          "100"
        ]
      },
      "200": {
        "value": "#f4f5f4",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#f4f5f4",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "silver-200",
        "path": [
          "core",
          "silver",
          "200"
        ]
      },
      "300": {
        "value": "#e9ebea",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#e9ebea",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "silver-300",
        "path": [
          "core",
          "silver",
          "300"
        ]
      },
      "400": {
        "value": "#dadddc",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#dadddc",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "silver-400",
        "path": [
          "core",
          "silver",
          "400"
        ]
      },
      "500": {
        "value": "#bbbfbe",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#bbbfbe",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "silver-500",
        "path": [
          "core",
          "silver",
          "500"
        ]
      },
      "600": {
        "value": "#868c8a",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#868c8a",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "silver-600",
        "path": [
          "core",
          "silver",
          "600"
        ]
      },
      "700": {
        "value": "#6b706f",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#6b706f",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "silver-700",
        "path": [
          "core",
          "silver",
          "700"
        ]
      },
      "800": {
        "value": "#555a58",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#555a58",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "silver-800",
        "path": [
          "core",
          "silver",
          "800"
        ]
      },
      "900": {
        "value": "#464a48",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#464a48",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "silver-900",
        "path": [
          "core",
          "silver",
          "900"
        ]
      },
      "1000": {
        "value": "#333535",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#333535",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "silver-1000",
        "path": [
          "core",
          "silver",
          "1000"
        ]
      },
      "1100": {
        "value": "#232424",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#232424",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "silver-1100",
        "path": [
          "core",
          "silver",
          "1100"
        ]
      },
      "1200": {
        "value": "#131313",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#131313",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "silver-1200",
        "path": [
          "core",
          "silver",
          "1200"
        ]
      }
    },
    "silver-dark": {
      "100": {
        "value": "#030303",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#030303",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "silver-dark-100",
        "path": [
          "core",
          "silver-dark",
          "100"
        ]
      },
      "200": {
        "value": "#111211",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#111211",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "silver-dark-200",
        "path": [
          "core",
          "silver-dark",
          "200"
        ]
      },
      "300": {
        "value": "#1d1d1d",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#1d1d1d",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "silver-dark-300",
        "path": [
          "core",
          "silver-dark",
          "300"
        ]
      },
      "400": {
        "value": "#292A2A",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#292A2A",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "silver-dark-400",
        "path": [
          "core",
          "silver-dark",
          "400"
        ]
      },
      "500": {
        "value": "#363737",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#363737",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "silver-dark-500",
        "path": [
          "core",
          "silver-dark",
          "500"
        ]
      },
      "600": {
        "value": "#646766",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#646766",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "silver-dark-600",
        "path": [
          "core",
          "silver-dark",
          "600"
        ]
      },
      "700": {
        "value": "#818584",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#818584",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "silver-dark-700",
        "path": [
          "core",
          "silver-dark",
          "700"
        ]
      },
      "800": {
        "value": "#939795",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#939795",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "silver-dark-800",
        "path": [
          "core",
          "silver-dark",
          "800"
        ]
      },
      "900": {
        "value": "#A8ACAB",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#A8ACAB",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "silver-dark-900",
        "path": [
          "core",
          "silver-dark",
          "900"
        ]
      },
      "1000": {
        "value": "#C3C6C6",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#C3C6C6",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "silver-dark-1000",
        "path": [
          "core",
          "silver-dark",
          "1000"
        ]
      },
      "1100": {
        "value": "#E7E8E8",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#E7E8E8",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "silver-dark-1100",
        "path": [
          "core",
          "silver-dark",
          "1100"
        ]
      },
      "1200": {
        "value": "#F3F3F3",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#F3F3F3",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "silver-dark-1200",
        "path": [
          "core",
          "silver-dark",
          "1200"
        ]
      }
    },
    "slate": {
      "100": {
        "value": "#fbfcfd",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#fbfcfd",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "slate-100",
        "path": [
          "core",
          "slate",
          "100"
        ]
      },
      "200": {
        "value": "#f3f5f9",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#f3f5f9",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "slate-200",
        "path": [
          "core",
          "slate",
          "200"
        ]
      },
      "300": {
        "value": "#e7ecf3",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#e7ecf3",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "slate-300",
        "path": [
          "core",
          "slate",
          "300"
        ]
      },
      "400": {
        "value": "#d5dee9",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#d5dee9",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "slate-400",
        "path": [
          "core",
          "slate",
          "400"
        ]
      },
      "500": {
        "value": "#b0bfd0",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#b0bfd0",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "slate-500",
        "path": [
          "core",
          "slate",
          "500"
        ]
      },
      "600": {
        "value": "#7d8c9e",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#7d8c9e",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "slate-600",
        "path": [
          "core",
          "slate",
          "600"
        ]
      },
      "700": {
        "value": "#64707f",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#64707f",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "slate-700",
        "path": [
          "core",
          "slate",
          "700"
        ]
      },
      "800": {
        "value": "#505a66",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#505a66",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "slate-800",
        "path": [
          "core",
          "slate",
          "800"
        ]
      },
      "900": {
        "value": "#424952",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#424952",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "slate-900",
        "path": [
          "core",
          "slate",
          "900"
        ]
      },
      "1000": {
        "value": "#30353c",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#30353c",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "slate-1000",
        "path": [
          "core",
          "slate",
          "1000"
        ]
      },
      "1100": {
        "value": "#212428",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#212428",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "slate-1100",
        "path": [
          "core",
          "slate",
          "1100"
        ]
      },
      "1200": {
        "value": "#121315",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#121315",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "slate-1200",
        "path": [
          "core",
          "slate",
          "1200"
        ]
      }
    },
    "slate-dark": {
      "100": {
        "value": "#030304",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#030304",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "slate-dark-100",
        "path": [
          "core",
          "slate-dark",
          "100"
        ]
      },
      "200": {
        "value": "#101214",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#101214",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "slate-dark-200",
        "path": [
          "core",
          "slate-dark",
          "200"
        ]
      },
      "300": {
        "value": "#17191C",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#17191C",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "slate-dark-300",
        "path": [
          "core",
          "slate-dark",
          "300"
        ]
      },
      "400": {
        "value": "#212429",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#212429",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "slate-dark-400",
        "path": [
          "core",
          "slate-dark",
          "400"
        ]
      },
      "500": {
        "value": "#272B30",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#272B30",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "slate-dark-500",
        "path": [
          "core",
          "slate-dark",
          "500"
        ]
      },
      "600": {
        "value": "#32383F",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#32383F",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "slate-dark-600",
        "path": [
          "core",
          "slate-dark",
          "600"
        ]
      },
      "700": {
        "value": "#5C6777",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#5C6777",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "slate-dark-700",
        "path": [
          "core",
          "slate-dark",
          "700"
        ]
      },
      "800": {
        "value": "#778598",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#778598",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "slate-dark-800",
        "path": [
          "core",
          "slate-dark",
          "800"
        ]
      },
      "900": {
        "value": "#9EACC0",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#9EACC0",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "slate-dark-900",
        "path": [
          "core",
          "slate-dark",
          "900"
        ]
      },
      "1000": {
        "value": "#BDC7D6",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#BDC7D6",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "slate-dark-1000",
        "path": [
          "core",
          "slate-dark",
          "1000"
        ]
      },
      "1100": {
        "value": "#E4E8EF",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#E4E8EF",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "slate-dark-1100",
        "path": [
          "core",
          "slate-dark",
          "1100"
        ]
      },
      "1200": {
        "value": "#F1F3F7",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#F1F3F7",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "slate-dark-1200",
        "path": [
          "core",
          "slate-dark",
          "1200"
        ]
      }
    },
    "turquoise": {
      "100": {
        "value": "#f9fcfd",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#f9fcfd",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "turquoise-100",
        "path": [
          "core",
          "turquoise",
          "100"
        ]
      },
      "200": {
        "value": "#eef6f9",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#eef6f9",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "turquoise-200",
        "path": [
          "core",
          "turquoise",
          "200"
        ]
      },
      "300": {
        "value": "#ddeef3",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#ddeef3",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "turquoise-300",
        "path": [
          "core",
          "turquoise",
          "300"
        ]
      },
      "400": {
        "value": "#c2e2eb",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#c2e2eb",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "turquoise-400",
        "path": [
          "core",
          "turquoise",
          "400"
        ]
      },
      "500": {
        "value": "#87c8d8",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#87c8d8",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "turquoise-500",
        "path": [
          "core",
          "turquoise",
          "500"
        ]
      },
      "600": {
        "value": "#1498af",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#1498af",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "turquoise-600",
        "path": [
          "core",
          "turquoise",
          "600"
        ]
      },
      "700": {
        "value": "#007a8f",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#007a8f",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "turquoise-700",
        "path": [
          "core",
          "turquoise",
          "700"
        ]
      },
      "800": {
        "value": "#006273",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#006273",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "turquoise-800",
        "path": [
          "core",
          "turquoise",
          "800"
        ]
      },
      "900": {
        "value": "#00505f",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#00505f",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "turquoise-900",
        "path": [
          "core",
          "turquoise",
          "900"
        ]
      },
      "1000": {
        "value": "#083a44",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#083a44",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "turquoise-1000",
        "path": [
          "core",
          "turquoise",
          "1000"
        ]
      },
      "1100": {
        "value": "#0e272d",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#0e272d",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "turquoise-1100",
        "path": [
          "core",
          "turquoise",
          "1100"
        ]
      },
      "1200": {
        "value": "#0a1417",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#0a1417",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "turquoise-1200",
        "path": [
          "core",
          "turquoise",
          "1200"
        ]
      }
    },
    "turquoise-dark": {
      "100": {
        "value": "#020405",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#020405",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "turquoise-dark-100",
        "path": [
          "core",
          "turquoise-dark",
          "100"
        ]
      },
      "200": {
        "value": "#0A1315",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#0A1315",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "turquoise-dark-200",
        "path": [
          "core",
          "turquoise-dark",
          "200"
        ]
      },
      "300": {
        "value": "#0E1C1F",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#0E1C1F",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "turquoise-dark-300",
        "path": [
          "core",
          "turquoise-dark",
          "300"
        ]
      },
      "400": {
        "value": "#0D2E35",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#0D2E35",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "turquoise-dark-400",
        "path": [
          "core",
          "turquoise-dark",
          "400"
        ]
      },
      "500": {
        "value": "#073D47",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#073D47",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "turquoise-dark-500",
        "path": [
          "core",
          "turquoise-dark",
          "500"
        ]
      },
      "600": {
        "value": "#007084",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#007084",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "turquoise-dark-600",
        "path": [
          "core",
          "turquoise-dark",
          "600"
        ]
      },
      "700": {
        "value": "#0F90A6",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#0F90A6",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "turquoise-dark-700",
        "path": [
          "core",
          "turquoise-dark",
          "700"
        ]
      },
      "800": {
        "value": "#42a2b6",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#42a2b6",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "turquoise-dark-800",
        "path": [
          "core",
          "turquoise-dark",
          "800"
        ]
      },
      "900": {
        "value": "#67B6C9",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#67B6C9",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "turquoise-dark-900",
        "path": [
          "core",
          "turquoise-dark",
          "900"
        ]
      },
      "1000": {
        "value": "#9ACEDC",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#9ACEDC",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "turquoise-dark-1000",
        "path": [
          "core",
          "turquoise-dark",
          "1000"
        ]
      },
      "1100": {
        "value": "#D8EBF1",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#D8EBF1",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "turquoise-dark-1100",
        "path": [
          "core",
          "turquoise-dark",
          "1100"
        ]
      },
      "1200": {
        "value": "#EBF5F7",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#EBF5F7",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "turquoise-dark-1200",
        "path": [
          "core",
          "turquoise-dark",
          "1200"
        ]
      }
    },
    "ultramarine": {
      "100": {
        "value": "#FDFBFF",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#FDFBFF",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "ultramarine-100",
        "path": [
          "core",
          "ultramarine",
          "100"
        ]
      },
      "200": {
        "value": "#FAF4FF",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#FAF4FF",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "ultramarine-200",
        "path": [
          "core",
          "ultramarine",
          "200"
        ]
      },
      "300": {
        "value": "#F2E6FF",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#F2E6FF",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "ultramarine-300",
        "path": [
          "core",
          "ultramarine",
          "300"
        ]
      },
      "400": {
        "value": "#E8D5FF",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#E8D5FF",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "ultramarine-400",
        "path": [
          "core",
          "ultramarine",
          "400"
        ]
      },
      "500": {
        "value": "#C69EFF",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#C69EFF",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "ultramarine-500",
        "path": [
          "core",
          "ultramarine",
          "500"
        ]
      },
      "600": {
        "value": "#A26EFF",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#A26EFF",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "ultramarine-600",
        "path": [
          "core",
          "ultramarine",
          "600"
        ]
      },
      "700": {
        "value": "#7E46FF",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#7E46FF",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "ultramarine-700",
        "path": [
          "core",
          "ultramarine",
          "700"
        ]
      },
      "800": {
        "value": "#5920FB",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#5920FB",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "ultramarine-800",
        "path": [
          "core",
          "ultramarine",
          "800"
        ]
      },
      "900": {
        "value": "#4C1FC7",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#4C1FC7",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "ultramarine-900",
        "path": [
          "core",
          "ultramarine",
          "900"
        ]
      },
      "1000": {
        "value": "#3B1B89",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#3B1B89",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "ultramarine-1000",
        "path": [
          "core",
          "ultramarine",
          "1000"
        ]
      },
      "1100": {
        "value": "#2A1656",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#2A1656",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "ultramarine-1100",
        "path": [
          "core",
          "ultramarine",
          "1100"
        ]
      },
      "1200": {
        "value": "#190E27",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#190E27",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "ultramarine-1200",
        "path": [
          "core",
          "ultramarine",
          "1200"
        ]
      }
    },
    "ultramarine-dark": {
      "100": {
        "value": "#06020A",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#06020A",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "ultramarine-dark-100",
        "path": [
          "core",
          "ultramarine-dark",
          "100"
        ]
      },
      "200": {
        "value": "#170C22",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#170C22",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "ultramarine-dark-200",
        "path": [
          "core",
          "ultramarine-dark",
          "200"
        ]
      },
      "300": {
        "value": "#1F1238",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#1F1238",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "ultramarine-dark-300",
        "path": [
          "core",
          "ultramarine-dark",
          "300"
        ]
      },
      "400": {
        "value": "#311869",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#311869",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "ultramarine-dark-400",
        "path": [
          "core",
          "ultramarine-dark",
          "400"
        ]
      },
      "500": {
        "value": "#3D1B90",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#3D1B90",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "ultramarine-dark-500",
        "path": [
          "core",
          "ultramarine-dark",
          "500"
        ]
      },
      "600": {
        "value": "#6F35FF",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#6F35FF",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "ultramarine-dark-600",
        "path": [
          "core",
          "ultramarine-dark",
          "600"
        ]
      },
      "700": {
        "value": "#9963FF",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#9963FF",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "ultramarine-dark-700",
        "path": [
          "core",
          "ultramarine-dark",
          "700"
        ]
      },
      "800": {
        "value": "#AF7FFF",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#AF7FFF",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "ultramarine-dark-800",
        "path": [
          "core",
          "ultramarine-dark",
          "800"
        ]
      },
      "900": {
        "value": "#C197FF",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#C197FF",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "ultramarine-dark-900",
        "path": [
          "core",
          "ultramarine-dark",
          "900"
        ]
      },
      "1000": {
        "value": "#D8B9FF",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#D8B9FF",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "ultramarine-dark-1000",
        "path": [
          "core",
          "ultramarine-dark",
          "1000"
        ]
      },
      "1100": {
        "value": "#F0E3FF",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#F0E3FF",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "ultramarine-dark-1100",
        "path": [
          "core",
          "ultramarine-dark",
          "1100"
        ]
      },
      "1200": {
        "value": "#F8F1FF",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#F8F1FF",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "ultramarine-dark-1200",
        "path": [
          "core",
          "ultramarine-dark",
          "1200"
        ]
      }
    },
    "yellow": {
      "100": {
        "value": "#FFFCF3",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#FFFCF3",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "yellow-100",
        "path": [
          "core",
          "yellow",
          "100"
        ]
      },
      "200": {
        "value": "#FFF6DA",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#FFF6DA",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "yellow-200",
        "path": [
          "core",
          "yellow",
          "200"
        ]
      },
      "300": {
        "value": "#FFEAA5",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#FFEAA5",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "yellow-300",
        "path": [
          "core",
          "yellow",
          "300"
        ]
      },
      "400": {
        "value": "#FFDA55",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#FFDA55",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "yellow-400",
        "path": [
          "core",
          "yellow",
          "400"
        ]
      },
      "500": {
        "value": "#D3AD11",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#D3AD11",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "yellow-500",
        "path": [
          "core",
          "yellow",
          "500"
        ]
      },
      "600": {
        "value": "#A68817",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#A68817",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "yellow-600",
        "path": [
          "core",
          "yellow",
          "600"
        ]
      },
      "700": {
        "value": "#856D18",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#856D18",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "yellow-700",
        "path": [
          "core",
          "yellow",
          "700"
        ]
      },
      "800": {
        "value": "#6B5817",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#6B5817",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "yellow-800",
        "path": [
          "core",
          "yellow",
          "800"
        ]
      },
      "900": {
        "value": "#574715",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#574715",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "yellow-900",
        "path": [
          "core",
          "yellow",
          "900"
        ]
      },
      "1000": {
        "value": "#3F3413",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#3F3413",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "yellow-1000",
        "path": [
          "core",
          "yellow",
          "1000"
        ]
      },
      "1100": {
        "value": "#2A230F",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#2A230F",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "yellow-1100",
        "path": [
          "core",
          "yellow",
          "1100"
        ]
      },
      "1200": {
        "value": "#171307",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#171307",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "yellow-1200",
        "path": [
          "core",
          "yellow",
          "1200"
        ]
      }
    },
    "yellow-dark": {
      "100": {
        "value": "#050401",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#050401",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "yellow-dark-100",
        "path": [
          "core",
          "yellow-dark",
          "100"
        ]
      },
      "200": {
        "value": "#151006",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#151006",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "yellow-dark-200",
        "path": [
          "core",
          "yellow-dark",
          "200"
        ]
      },
      "300": {
        "value": "#1E190B",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#1E190B",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "yellow-dark-300",
        "path": [
          "core",
          "yellow-dark",
          "300"
        ]
      },
      "400": {
        "value": "#322911",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#322911",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "yellow-dark-400",
        "path": [
          "core",
          "yellow-dark",
          "400"
        ]
      },
      "500": {
        "value": "#413613",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#413613",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "yellow-dark-500",
        "path": [
          "core",
          "yellow-dark",
          "500"
        ]
      },
      "600": {
        "value": "#796317",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#796317",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "yellow-dark-600",
        "path": [
          "core",
          "yellow-dark",
          "600"
        ]
      },
      "700": {
        "value": "#9D8117",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#9D8117",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "yellow-dark-700",
        "path": [
          "core",
          "yellow-dark",
          "700"
        ]
      },
      "800": {
        "value": "#B69515",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#B69515",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "yellow-dark-800",
        "path": [
          "core",
          "yellow-dark",
          "800"
        ]
      },
      "900": {
        "value": "#CCA712",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#CCA712",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "yellow-dark-900",
        "path": [
          "core",
          "yellow-dark",
          "900"
        ]
      },
      "1000": {
        "value": "#EDC209",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#EDC209",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "yellow-dark-1000",
        "path": [
          "core",
          "yellow-dark",
          "1000"
        ]
      },
      "1100": {
        "value": "#FFDA55",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#FFDA55",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "yellow-dark-1100",
        "path": [
          "core",
          "yellow-dark",
          "1100"
        ]
      },
      "1200": {
        "value": "#FFF3CD",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#FFF3CD",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "yellow-dark-1200",
        "path": [
          "core",
          "yellow-dark",
          "1200"
        ]
      }
    },
    "border-width": {
      "0": {
        "value": "0rem",
        "type": "borderWidth",
        "attributes": {
          "category": "size"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "0rem",
          "type": "borderWidth",
          "attributes": {
            "category": "size"
          }
        },
        "name": "border-width-0",
        "path": [
          "core",
          "border-width",
          "0"
        ]
      },
      "1": {
        "value": "0.063rem",
        "type": "borderWidth",
        "attributes": {
          "category": "size"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "0.063rem",
          "type": "borderWidth",
          "attributes": {
            "category": "size"
          }
        },
        "name": "border-width-1",
        "path": [
          "core",
          "border-width",
          "1"
        ]
      },
      "2": {
        "value": "0.125rem",
        "type": "borderWidth",
        "attributes": {
          "category": "size"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "0.125rem",
          "type": "borderWidth",
          "attributes": {
            "category": "size"
          }
        },
        "name": "border-width-2",
        "path": [
          "core",
          "border-width",
          "2"
        ]
      },
      "3": {
        "value": "0.188rem",
        "type": "borderWidth",
        "attributes": {
          "category": "size"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "0.188rem",
          "type": "borderWidth",
          "attributes": {
            "category": "size"
          }
        },
        "name": "border-width-3",
        "path": [
          "core",
          "border-width",
          "3"
        ]
      },
      "4": {
        "value": "0.25rem",
        "type": "borderWidth",
        "attributes": {
          "category": "size"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "0.25rem",
          "type": "borderWidth",
          "attributes": {
            "category": "size"
          }
        },
        "name": "border-width-4",
        "path": [
          "core",
          "border-width",
          "4"
        ]
      },
      "5": {
        "value": "0.313rem",
        "type": "borderWidth",
        "attributes": {
          "category": "size"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "0.313rem",
          "type": "borderWidth",
          "attributes": {
            "category": "size"
          }
        },
        "name": "border-width-5",
        "path": [
          "core",
          "border-width",
          "5"
        ]
      },
      "6": {
        "value": "0.375rem",
        "type": "borderWidth",
        "attributes": {
          "category": "size"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "0.375rem",
          "type": "borderWidth",
          "attributes": {
            "category": "size"
          }
        },
        "name": "border-width-6",
        "path": [
          "core",
          "border-width",
          "6"
        ]
      }
    },
    "spacing": {
      "0": {
        "value": "0",
        "type": "spacing",
        "attributes": {
          "category": "size"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "0",
          "type": "spacing",
          "attributes": {
            "category": "size"
          }
        },
        "name": "spacing-0",
        "path": [
          "core",
          "spacing",
          "0"
        ]
      },
      "1": {
        "value": "0.25rem",
        "type": "spacing",
        "attributes": {
          "category": "size"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "0.25rem",
          "type": "spacing",
          "attributes": {
            "category": "size"
          }
        },
        "name": "spacing-1",
        "path": [
          "core",
          "spacing",
          "1"
        ]
      },
      "2": {
        "value": "0.50rem",
        "type": "spacing",
        "attributes": {
          "category": "size"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "0.50rem",
          "type": "spacing",
          "attributes": {
            "category": "size"
          }
        },
        "name": "spacing-2",
        "path": [
          "core",
          "spacing",
          "2"
        ]
      },
      "3": {
        "value": "0.75rem",
        "type": "spacing",
        "attributes": {
          "category": "size"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "0.75rem",
          "type": "spacing",
          "attributes": {
            "category": "size"
          }
        },
        "name": "spacing-3",
        "path": [
          "core",
          "spacing",
          "3"
        ]
      },
      "4": {
        "value": "1rem",
        "type": "spacing",
        "attributes": {
          "category": "size"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "1rem",
          "type": "spacing",
          "attributes": {
            "category": "size"
          }
        },
        "name": "spacing-4",
        "path": [
          "core",
          "spacing",
          "4"
        ]
      },
      "5": {
        "value": "1.25rem",
        "type": "spacing",
        "attributes": {
          "category": "size"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "1.25rem",
          "type": "spacing",
          "attributes": {
            "category": "size"
          }
        },
        "name": "spacing-5",
        "path": [
          "core",
          "spacing",
          "5"
        ]
      },
      "6": {
        "value": "1.5rem",
        "type": "spacing",
        "attributes": {
          "category": "size"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "1.5rem",
          "type": "spacing",
          "attributes": {
            "category": "size"
          }
        },
        "name": "spacing-6",
        "path": [
          "core",
          "spacing",
          "6"
        ]
      },
      "7": {
        "value": "1.75rem",
        "type": "spacing",
        "attributes": {
          "category": "size"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "1.75rem",
          "type": "spacing",
          "attributes": {
            "category": "size"
          }
        },
        "name": "spacing-7",
        "path": [
          "core",
          "spacing",
          "7"
        ]
      },
      "8": {
        "value": "2rem",
        "type": "spacing",
        "attributes": {
          "category": "size"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "2rem",
          "type": "spacing",
          "attributes": {
            "category": "size"
          }
        },
        "name": "spacing-8",
        "path": [
          "core",
          "spacing",
          "8"
        ]
      },
      "9": {
        "value": "2.25rem",
        "type": "spacing",
        "attributes": {
          "category": "size"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "2.25rem",
          "type": "spacing",
          "attributes": {
            "category": "size"
          }
        },
        "name": "spacing-9",
        "path": [
          "core",
          "spacing",
          "9"
        ]
      },
      "10": {
        "value": "2.5rem",
        "type": "spacing",
        "attributes": {
          "category": "size"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "2.5rem",
          "type": "spacing",
          "attributes": {
            "category": "size"
          }
        },
        "name": "spacing-10",
        "path": [
          "core",
          "spacing",
          "10"
        ]
      },
      "11": {
        "value": "2.75rem",
        "type": "spacing",
        "attributes": {
          "category": "size"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "2.75rem",
          "type": "spacing",
          "attributes": {
            "category": "size"
          }
        },
        "name": "spacing-11",
        "path": [
          "core",
          "spacing",
          "11"
        ]
      },
      "12": {
        "value": "3rem",
        "type": "spacing",
        "attributes": {
          "category": "size"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "3rem",
          "type": "spacing",
          "attributes": {
            "category": "size"
          }
        },
        "name": "spacing-12",
        "path": [
          "core",
          "spacing",
          "12"
        ]
      },
      "13": {
        "value": "3.25rem",
        "type": "spacing",
        "attributes": {
          "category": "size"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "3.25rem",
          "type": "spacing",
          "attributes": {
            "category": "size"
          }
        },
        "name": "spacing-13",
        "path": [
          "core",
          "spacing",
          "13"
        ]
      },
      "14": {
        "value": "3.5rem",
        "type": "spacing",
        "attributes": {
          "category": "size"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "3.5rem",
          "type": "spacing",
          "attributes": {
            "category": "size"
          }
        },
        "name": "spacing-14",
        "path": [
          "core",
          "spacing",
          "14"
        ]
      },
      "15": {
        "value": "3.75rem",
        "type": "spacing",
        "attributes": {
          "category": "size"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "3.75rem",
          "type": "spacing",
          "attributes": {
            "category": "size"
          }
        },
        "name": "spacing-15",
        "path": [
          "core",
          "spacing",
          "15"
        ]
      },
      "16": {
        "value": "4rem",
        "type": "spacing",
        "attributes": {
          "category": "size"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "4rem",
          "type": "spacing",
          "attributes": {
            "category": "size"
          }
        },
        "name": "spacing-16",
        "path": [
          "core",
          "spacing",
          "16"
        ]
      },
      "17": {
        "value": "4.25rem",
        "type": "spacing",
        "attributes": {
          "category": "size"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "4.25rem",
          "type": "spacing",
          "attributes": {
            "category": "size"
          }
        },
        "name": "spacing-17",
        "path": [
          "core",
          "spacing",
          "17"
        ]
      },
      "18": {
        "value": "4.5rem",
        "type": "spacing",
        "attributes": {
          "category": "size"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "4.5rem",
          "type": "spacing",
          "attributes": {
            "category": "size"
          }
        },
        "name": "spacing-18",
        "path": [
          "core",
          "spacing",
          "18"
        ]
      },
      "19": {
        "value": "4.75rem",
        "type": "spacing",
        "attributes": {
          "category": "size"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "4.75rem",
          "type": "spacing",
          "attributes": {
            "category": "size"
          }
        },
        "name": "spacing-19",
        "path": [
          "core",
          "spacing",
          "19"
        ]
      },
      "20": {
        "value": "5rem",
        "type": "spacing",
        "attributes": {
          "category": "size"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "5rem",
          "type": "spacing",
          "attributes": {
            "category": "size"
          }
        },
        "name": "spacing-20",
        "path": [
          "core",
          "spacing",
          "20"
        ]
      },
      "21": {
        "value": "5.25rem",
        "type": "spacing",
        "attributes": {
          "category": "size"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "5.25rem",
          "type": "spacing",
          "attributes": {
            "category": "size"
          }
        },
        "name": "spacing-21",
        "path": [
          "core",
          "spacing",
          "21"
        ]
      },
      "22": {
        "value": "5.5rem",
        "type": "spacing",
        "attributes": {
          "category": "size"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "5.5rem",
          "type": "spacing",
          "attributes": {
            "category": "size"
          }
        },
        "name": "spacing-22",
        "path": [
          "core",
          "spacing",
          "22"
        ]
      },
      "23": {
        "value": "5.75rem",
        "type": "spacing",
        "attributes": {
          "category": "size"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "5.75rem",
          "type": "spacing",
          "attributes": {
            "category": "size"
          }
        },
        "name": "spacing-23",
        "path": [
          "core",
          "spacing",
          "23"
        ]
      },
      "24": {
        "value": "6rem",
        "type": "spacing",
        "attributes": {
          "category": "size"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "6rem",
          "type": "spacing",
          "attributes": {
            "category": "size"
          }
        },
        "name": "spacing-24",
        "path": [
          "core",
          "spacing",
          "24"
        ]
      },
      "1px": {
        "value": "0.063rem",
        "type": "spacing",
        "attributes": {
          "category": "size"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "0.063rem",
          "type": "spacing",
          "attributes": {
            "category": "size"
          }
        },
        "name": "spacing-1px",
        "path": [
          "core",
          "spacing",
          "1px"
        ]
      },
      "2px": {
        "value": "0.125rem",
        "type": "spacing",
        "attributes": {
          "category": "size"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "0.125rem",
          "type": "spacing",
          "attributes": {
            "category": "size"
          }
        },
        "name": "spacing-2px",
        "path": [
          "core",
          "spacing",
          "2px"
        ]
      }
    },
    "font-family": {
      "refrigerator-deluxe": {
        "value": "RefrigeratorDeluxe",
        "type": "fontFamilies",
        "attributes": {
          "category": "content"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "RefrigeratorDeluxe",
          "type": "fontFamilies",
          "attributes": {
            "category": "content"
          }
        },
        "name": "font-family-refrigerator-deluxe",
        "path": [
          "core",
          "font-family",
          "refrigerator-deluxe"
        ]
      },
      "proxima-nova-condensed": {
        "value": "ProximaNovaCondensed",
        "type": "fontFamilies",
        "attributes": {
          "category": "content"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "ProximaNovaCondensed",
          "type": "fontFamilies",
          "attributes": {
            "category": "content"
          }
        },
        "name": "font-family-proxima-nova-condensed",
        "path": [
          "core",
          "font-family",
          "proxima-nova-condensed"
        ]
      },
      "brown-pro": {
        "value": "brown-pro",
        "type": "fontFamilies",
        "attributes": {
          "category": "content"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "brown-pro",
          "type": "fontFamilies",
          "attributes": {
            "category": "content"
          }
        },
        "name": "font-family-brown-pro",
        "path": [
          "core",
          "font-family",
          "brown-pro"
        ]
      },
      "sf-pro": {
        "value": "system-ui, -apple-system, BlinkMacSystemFont",
        "type": "fontFamilies",
        "attributes": {
          "category": "content"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "system-ui, -apple-system, BlinkMacSystemFont",
          "type": "fontFamilies",
          "attributes": {
            "category": "content"
          }
        },
        "name": "font-family-sf-pro",
        "path": [
          "core",
          "font-family",
          "sf-pro"
        ]
      },
      "roboto": {
        "value": "Roboto",
        "type": "fontFamilies",
        "attributes": {
          "category": "content"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "Roboto",
          "type": "fontFamilies",
          "attributes": {
            "category": "content"
          }
        },
        "name": "font-family-roboto",
        "path": [
          "core",
          "font-family",
          "roboto"
        ]
      },
      "roboto-flex": {
        "value": "Roboto Flex",
        "type": "fontFamilies",
        "attributes": {
          "category": "content"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "Roboto Flex",
          "type": "fontFamilies",
          "attributes": {
            "category": "content"
          }
        },
        "name": "font-family-roboto-flex",
        "path": [
          "core",
          "font-family",
          "roboto-flex"
        ]
      },
      "oswald": {
        "value": "oswald",
        "type": "fontFamilies",
        "attributes": {
          "category": "content"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "oswald",
          "type": "fontFamilies",
          "attributes": {
            "category": "content"
          }
        },
        "name": "font-family-oswald",
        "path": [
          "core",
          "font-family",
          "oswald"
        ]
      },
      "roboto-condensed": {
        "value": "Roboto Condensed",
        "type": "fontFamilies",
        "attributes": {
          "category": "content"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "Roboto Condensed",
          "type": "fontFamilies",
          "attributes": {
            "category": "content"
          }
        },
        "name": "font-family-roboto-condensed",
        "path": [
          "core",
          "font-family",
          "roboto-condensed"
        ]
      },
      "oswald-static": {
        "value": "Oswald Static",
        "type": "fontFamilies",
        "attributes": {
          "category": "content"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "Oswald Static",
          "type": "fontFamilies",
          "attributes": {
            "category": "content"
          }
        },
        "name": "font-family-oswald-static",
        "path": [
          "core",
          "font-family",
          "oswald-static"
        ]
      }
    },
    "font-size": {
      "1": {
        "value": "0.75rem",
        "type": "fontSizes",
        "attributes": {
          "category": "size"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "0.75rem",
          "type": "fontSizes",
          "attributes": {
            "category": "size"
          }
        },
        "name": "font-size-1",
        "path": [
          "core",
          "font-size",
          "1"
        ]
      },
      "2": {
        "value": "0.875rem",
        "type": "fontSizes",
        "attributes": {
          "category": "size"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "0.875rem",
          "type": "fontSizes",
          "attributes": {
            "category": "size"
          }
        },
        "name": "font-size-2",
        "path": [
          "core",
          "font-size",
          "2"
        ]
      },
      "3": {
        "value": "1rem",
        "type": "fontSizes",
        "attributes": {
          "category": "size"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "1rem",
          "type": "fontSizes",
          "attributes": {
            "category": "size"
          }
        },
        "name": "font-size-3",
        "path": [
          "core",
          "font-size",
          "3"
        ]
      },
      "4": {
        "value": "1.125rem",
        "type": "fontSizes",
        "attributes": {
          "category": "size"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "1.125rem",
          "type": "fontSizes",
          "attributes": {
            "category": "size"
          }
        },
        "name": "font-size-4",
        "path": [
          "core",
          "font-size",
          "4"
        ]
      },
      "5": {
        "value": "1.25rem",
        "type": "fontSizes",
        "attributes": {
          "category": "size"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "1.25rem",
          "type": "fontSizes",
          "attributes": {
            "category": "size"
          }
        },
        "name": "font-size-5",
        "path": [
          "core",
          "font-size",
          "5"
        ]
      },
      "6": {
        "value": "1.375rem",
        "type": "fontSizes",
        "attributes": {
          "category": "size"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "1.375rem",
          "type": "fontSizes",
          "attributes": {
            "category": "size"
          }
        },
        "name": "font-size-6",
        "path": [
          "core",
          "font-size",
          "6"
        ]
      },
      "7": {
        "value": "1.5rem",
        "type": "fontSizes",
        "attributes": {
          "category": "size"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "1.5rem",
          "type": "fontSizes",
          "attributes": {
            "category": "size"
          }
        },
        "name": "font-size-7",
        "path": [
          "core",
          "font-size",
          "7"
        ]
      },
      "8": {
        "value": "1.75rem",
        "type": "fontSizes",
        "attributes": {
          "category": "size"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "1.75rem",
          "type": "fontSizes",
          "attributes": {
            "category": "size"
          }
        },
        "name": "font-size-8",
        "path": [
          "core",
          "font-size",
          "8"
        ]
      },
      "9": {
        "value": "2rem",
        "type": "fontSizes",
        "attributes": {
          "category": "size"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "2rem",
          "type": "fontSizes",
          "attributes": {
            "category": "size"
          }
        },
        "name": "font-size-9",
        "path": [
          "core",
          "font-size",
          "9"
        ]
      },
      "10": {
        "value": "2.25rem",
        "type": "fontSizes",
        "attributes": {
          "category": "size"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "2.25rem",
          "type": "fontSizes",
          "attributes": {
            "category": "size"
          }
        },
        "name": "font-size-10",
        "path": [
          "core",
          "font-size",
          "10"
        ]
      },
      "11": {
        "value": "2.625rem",
        "type": "fontSizes",
        "attributes": {
          "category": "size"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "2.625rem",
          "type": "fontSizes",
          "attributes": {
            "category": "size"
          }
        },
        "name": "font-size-11",
        "path": [
          "core",
          "font-size",
          "11"
        ]
      },
      "12": {
        "value": "3rem",
        "type": "fontSizes",
        "attributes": {
          "category": "size"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "3rem",
          "type": "fontSizes",
          "attributes": {
            "category": "size"
          }
        },
        "name": "font-size-12",
        "path": [
          "core",
          "font-size",
          "12"
        ]
      },
      "13": {
        "value": "3.25rem",
        "type": "fontSizes",
        "attributes": {
          "category": "size"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "3.25rem",
          "type": "fontSizes",
          "attributes": {
            "category": "size"
          }
        },
        "name": "font-size-13",
        "path": [
          "core",
          "font-size",
          "13"
        ]
      },
      "14": {
        "value": "3.625rem",
        "type": "fontSizes",
        "attributes": {
          "category": "size"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "3.625rem",
          "type": "fontSizes",
          "attributes": {
            "category": "size"
          }
        },
        "name": "font-size-14",
        "path": [
          "core",
          "font-size",
          "14"
        ]
      },
      "15": {
        "value": "4rem",
        "type": "fontSizes",
        "attributes": {
          "category": "size"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "4rem",
          "type": "fontSizes",
          "attributes": {
            "category": "size"
          }
        },
        "name": "font-size-15",
        "path": [
          "core",
          "font-size",
          "15"
        ]
      }
    },
    "line-height": {
      "1": {
        "value": "1rem",
        "type": "lineHeights",
        "attributes": {
          "category": "size"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "1rem",
          "type": "lineHeights",
          "attributes": {
            "category": "size"
          }
        },
        "name": "line-height-1",
        "path": [
          "core",
          "line-height",
          "1"
        ]
      },
      "2": {
        "value": "1.125rem",
        "type": "lineHeights",
        "attributes": {
          "category": "size"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "1.125rem",
          "type": "lineHeights",
          "attributes": {
            "category": "size"
          }
        },
        "name": "line-height-2",
        "path": [
          "core",
          "line-height",
          "2"
        ]
      },
      "3": {
        "value": "1.25rem",
        "type": "lineHeights",
        "attributes": {
          "category": "size"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "1.25rem",
          "type": "lineHeights",
          "attributes": {
            "category": "size"
          }
        },
        "name": "line-height-3",
        "path": [
          "core",
          "line-height",
          "3"
        ]
      },
      "4": {
        "value": "1.375rem",
        "type": "lineHeights",
        "attributes": {
          "category": "size"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "1.375rem",
          "type": "lineHeights",
          "attributes": {
            "category": "size"
          }
        },
        "name": "line-height-4",
        "path": [
          "core",
          "line-height",
          "4"
        ]
      },
      "5": {
        "value": "1.5rem",
        "type": "lineHeights",
        "attributes": {
          "category": "size"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "1.5rem",
          "type": "lineHeights",
          "attributes": {
            "category": "size"
          }
        },
        "name": "line-height-5",
        "path": [
          "core",
          "line-height",
          "5"
        ]
      },
      "6": {
        "value": "1.75rem",
        "type": "lineHeights",
        "attributes": {
          "category": "size"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "1.75rem",
          "type": "lineHeights",
          "attributes": {
            "category": "size"
          }
        },
        "name": "line-height-6",
        "path": [
          "core",
          "line-height",
          "6"
        ]
      },
      "7": {
        "value": "2rem",
        "type": "lineHeights",
        "attributes": {
          "category": "size"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "2rem",
          "type": "lineHeights",
          "attributes": {
            "category": "size"
          }
        },
        "name": "line-height-7",
        "path": [
          "core",
          "line-height",
          "7"
        ]
      },
      "8": {
        "value": "2.25rem",
        "type": "lineHeights",
        "attributes": {
          "category": "size"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "2.25rem",
          "type": "lineHeights",
          "attributes": {
            "category": "size"
          }
        },
        "name": "line-height-8",
        "path": [
          "core",
          "line-height",
          "8"
        ]
      },
      "9": {
        "value": "3rem",
        "type": "lineHeights",
        "attributes": {
          "category": "size"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "3rem",
          "type": "lineHeights",
          "attributes": {
            "category": "size"
          }
        },
        "name": "line-height-9",
        "path": [
          "core",
          "line-height",
          "9"
        ]
      },
      "10": {
        "value": "4rem",
        "type": "lineHeights",
        "attributes": {
          "category": "size"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "4rem",
          "type": "lineHeights",
          "attributes": {
            "category": "size"
          }
        },
        "name": "line-height-10",
        "path": [
          "core",
          "line-height",
          "10"
        ]
      }
    },
    "font-weight": {
      "thin": {
        "value": "100",
        "type": "fontWeights",
        "attributes": {},
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "100",
          "type": "fontWeights",
          "attributes": {}
        },
        "name": "font-weight-thin",
        "path": [
          "core",
          "font-weight",
          "thin"
        ]
      },
      "extraLight": {
        "value": "200",
        "type": "fontWeights",
        "attributes": {},
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "200",
          "type": "fontWeights",
          "attributes": {}
        },
        "name": "font-weight-extraLight",
        "path": [
          "core",
          "font-weight",
          "extraLight"
        ]
      },
      "light": {
        "value": "300",
        "type": "fontWeights",
        "attributes": {},
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "300",
          "type": "fontWeights",
          "attributes": {}
        },
        "name": "font-weight-light",
        "path": [
          "core",
          "font-weight",
          "light"
        ]
      },
      "regular": {
        "value": "400",
        "type": "fontWeights",
        "attributes": {},
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "400",
          "type": "fontWeights",
          "attributes": {}
        },
        "name": "font-weight-regular",
        "path": [
          "core",
          "font-weight",
          "regular"
        ]
      },
      "medium": {
        "value": "500",
        "type": "fontWeights",
        "attributes": {},
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "500",
          "type": "fontWeights",
          "attributes": {}
        },
        "name": "font-weight-medium",
        "path": [
          "core",
          "font-weight",
          "medium"
        ]
      },
      "semiBold": {
        "value": "600",
        "type": "fontWeights",
        "attributes": {},
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "600",
          "type": "fontWeights",
          "attributes": {}
        },
        "name": "font-weight-semiBold",
        "path": [
          "core",
          "font-weight",
          "semiBold"
        ]
      },
      "bold": {
        "value": "700",
        "type": "fontWeights",
        "attributes": {},
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "700",
          "type": "fontWeights",
          "attributes": {}
        },
        "name": "font-weight-bold",
        "path": [
          "core",
          "font-weight",
          "bold"
        ]
      },
      "extraBold": {
        "value": "800",
        "type": "fontWeights",
        "attributes": {},
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "800",
          "type": "fontWeights",
          "attributes": {}
        },
        "name": "font-weight-extraBold",
        "path": [
          "core",
          "font-weight",
          "extraBold"
        ]
      },
      "black": {
        "value": "900",
        "type": "fontWeights",
        "attributes": {},
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "900",
          "type": "fontWeights",
          "attributes": {}
        },
        "name": "font-weight-black",
        "path": [
          "core",
          "font-weight",
          "black"
        ]
      },
      "sf-pro-condensed-regular": {
        "value": "335",
        "type": "fontWeights",
        "attributes": {},
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "335",
          "type": "fontWeights",
          "attributes": {}
        },
        "name": "font-weight-sf-pro-condensed-regular",
        "path": [
          "core",
          "font-weight",
          "sf-pro-condensed-regular"
        ]
      },
      "sf-pro-condensed-medium": {
        "value": "445",
        "type": "fontWeights",
        "attributes": {},
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "445",
          "type": "fontWeights",
          "attributes": {}
        },
        "name": "font-weight-sf-pro-condensed-medium",
        "path": [
          "core",
          "font-weight",
          "sf-pro-condensed-medium"
        ]
      },
      "sf-pro-condensed-semibold": {
        "value": "555",
        "type": "fontWeights",
        "attributes": {},
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "555",
          "type": "fontWeights",
          "attributes": {}
        },
        "name": "font-weight-sf-pro-condensed-semibold",
        "path": [
          "core",
          "font-weight",
          "sf-pro-condensed-semibold"
        ]
      },
      "sf-pro-condensed-heavy": {
        "value": "775",
        "type": "fontWeights",
        "attributes": {},
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "775",
          "type": "fontWeights",
          "attributes": {}
        },
        "name": "font-weight-sf-pro-condensed-heavy",
        "path": [
          "core",
          "font-weight",
          "sf-pro-condensed-heavy"
        ]
      },
      "condensed-ultralight": {
        "value": "5",
        "type": "fontWeights",
        "attributes": {},
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "5",
          "type": "fontWeights",
          "attributes": {}
        },
        "name": "font-weight-condensed-ultralight",
        "path": [
          "core",
          "font-weight",
          "condensed-ultralight"
        ]
      },
      "sf-pro-condensed-thin": {
        "value": "115",
        "type": "fontWeights",
        "attributes": {},
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "115",
          "type": "fontWeights",
          "attributes": {}
        },
        "name": "font-weight-sf-pro-condensed-thin",
        "path": [
          "core",
          "font-weight",
          "sf-pro-condensed-thin"
        ]
      },
      "sf-pro-condensed-light": {
        "value": "225",
        "type": "fontWeights",
        "attributes": {},
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "225",
          "type": "fontWeights",
          "attributes": {}
        },
        "name": "font-weight-sf-pro-condensed-light",
        "path": [
          "core",
          "font-weight",
          "sf-pro-condensed-light"
        ]
      },
      "sf-pro-condensed-bold": {
        "value": "665",
        "type": "fontWeights",
        "attributes": {},
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "665",
          "type": "fontWeights",
          "attributes": {}
        },
        "name": "font-weight-sf-pro-condensed-bold",
        "path": [
          "core",
          "font-weight",
          "sf-pro-condensed-bold"
        ]
      },
      "sf-pro-condensed-black": {
        "value": "885",
        "type": "fontWeights",
        "attributes": {},
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "885",
          "type": "fontWeights",
          "attributes": {}
        },
        "name": "font-weight-sf-pro-condensed-black",
        "path": [
          "core",
          "font-weight",
          "sf-pro-condensed-black"
        ]
      }
    },
    "box-shadow": {
      "1": {
        "value": {
          "x": "0",
          "y": "0.063rem",
          "blur": "0.063rem",
          "spread": "0",
          "color": "rgba(0,0,0,.10)",
          "type": "dropShadow"
        },
        "type": "boxShadow",
        "attributes": {
          "category": "boxShadow"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": {
            "x": "0",
            "y": "0.063rem",
            "blur": "0.063rem",
            "spread": "0",
            "color": "rgba(0,0,0,.10)",
            "type": "dropShadow"
          },
          "type": "boxShadow",
          "attributes": {
            "category": "boxShadow"
          }
        },
        "name": "box-shadow-1",
        "path": [
          "core",
          "box-shadow",
          "1"
        ]
      },
      "2": {
        "value": {
          "x": "0",
          "y": "0.125rem",
          "blur": "0.125rem",
          "spread": "0",
          "color": "rgba(0,0,0,0.1)",
          "type": "dropShadow"
        },
        "type": "boxShadow",
        "attributes": {
          "category": "boxShadow"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": {
            "x": "0",
            "y": "0.125rem",
            "blur": "0.125rem",
            "spread": "0",
            "color": "rgba(0,0,0,0.1)",
            "type": "dropShadow"
          },
          "type": "boxShadow",
          "attributes": {
            "category": "boxShadow"
          }
        },
        "name": "box-shadow-2",
        "path": [
          "core",
          "box-shadow",
          "2"
        ]
      },
      "3": {
        "value": {
          "x": "0",
          "y": "0.188rem",
          "blur": "0.188rem",
          "spread": "0",
          "color": "rgba(0,0,0,0.1)",
          "type": "dropShadow"
        },
        "type": "boxShadow",
        "attributes": {
          "category": "boxShadow"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": {
            "x": "0",
            "y": "0.188rem",
            "blur": "0.188rem",
            "spread": "0",
            "color": "rgba(0,0,0,0.1)",
            "type": "dropShadow"
          },
          "type": "boxShadow",
          "attributes": {
            "category": "boxShadow"
          }
        },
        "name": "box-shadow-3",
        "path": [
          "core",
          "box-shadow",
          "3"
        ]
      },
      "4": {
        "value": {
          "x": "0",
          "y": "0.25rem",
          "blur": "0.25rem",
          "spread": "0",
          "color": "rgba(0,0,0,0.1)",
          "type": "dropShadow"
        },
        "type": "boxShadow",
        "attributes": {
          "category": "boxShadow"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": {
            "x": "0",
            "y": "0.25rem",
            "blur": "0.25rem",
            "spread": "0",
            "color": "rgba(0,0,0,0.1)",
            "type": "dropShadow"
          },
          "type": "boxShadow",
          "attributes": {
            "category": "boxShadow"
          }
        },
        "name": "box-shadow-4",
        "path": [
          "core",
          "box-shadow",
          "4"
        ]
      },
      "5": {
        "value": {
          "x": "0",
          "y": "0.313rem",
          "blur": "0.313rem",
          "spread": "0",
          "color": "rgba(0,0,0,0.1)",
          "type": "dropShadow"
        },
        "type": "boxShadow",
        "attributes": {
          "category": "boxShadow"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": {
            "x": "0",
            "y": "0.313rem",
            "blur": "0.313rem",
            "spread": "0",
            "color": "rgba(0,0,0,0.1)",
            "type": "dropShadow"
          },
          "type": "boxShadow",
          "attributes": {
            "category": "boxShadow"
          }
        },
        "name": "box-shadow-5",
        "path": [
          "core",
          "box-shadow",
          "5"
        ]
      },
      "6": {
        "value": {
          "x": "0",
          "y": "0.375rem",
          "blur": "0.375rem",
          "spread": "0",
          "color": "rgba(0,0,0,0.08)",
          "type": "dropShadow"
        },
        "type": "boxShadow",
        "attributes": {
          "category": "boxShadow"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": {
            "x": "0",
            "y": "0.375rem",
            "blur": "0.375rem",
            "spread": "0",
            "color": "rgba(0,0,0,0.08)",
            "type": "dropShadow"
          },
          "type": "boxShadow",
          "attributes": {
            "category": "boxShadow"
          }
        },
        "name": "box-shadow-6",
        "path": [
          "core",
          "box-shadow",
          "6"
        ]
      },
      "7": {
        "value": {
          "x": "0",
          "y": "0.438rem",
          "blur": "0.438rem",
          "spread": "0",
          "color": "rgba(0,0,0,0.08)",
          "type": "dropShadow"
        },
        "type": "boxShadow",
        "attributes": {
          "category": "boxShadow"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": {
            "x": "0",
            "y": "0.438rem",
            "blur": "0.438rem",
            "spread": "0",
            "color": "rgba(0,0,0,0.08)",
            "type": "dropShadow"
          },
          "type": "boxShadow",
          "attributes": {
            "category": "boxShadow"
          }
        },
        "name": "box-shadow-7",
        "path": [
          "core",
          "box-shadow",
          "7"
        ]
      },
      "8": {
        "value": {
          "x": "0",
          "y": "0.5rem",
          "blur": "0.5rem",
          "spread": "0",
          "color": "rgba(0,0,0,0.08)",
          "type": "dropShadow"
        },
        "type": "boxShadow",
        "attributes": {
          "category": "boxShadow"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": {
            "x": "0",
            "y": "0.5rem",
            "blur": "0.5rem",
            "spread": "0",
            "color": "rgba(0,0,0,0.08)",
            "type": "dropShadow"
          },
          "type": "boxShadow",
          "attributes": {
            "category": "boxShadow"
          }
        },
        "name": "box-shadow-8",
        "path": [
          "core",
          "box-shadow",
          "8"
        ]
      },
      "9": {
        "value": {
          "x": "0",
          "y": "0.563rem",
          "blur": "0.563rem",
          "spread": "0",
          "color": "rgba(0,0,0,0.08)",
          "type": "dropShadow"
        },
        "type": "boxShadow",
        "attributes": {
          "category": "boxShadow"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": {
            "x": "0",
            "y": "0.563rem",
            "blur": "0.563rem",
            "spread": "0",
            "color": "rgba(0,0,0,0.08)",
            "type": "dropShadow"
          },
          "type": "boxShadow",
          "attributes": {
            "category": "boxShadow"
          }
        },
        "name": "box-shadow-9",
        "path": [
          "core",
          "box-shadow",
          "9"
        ]
      },
      "10": {
        "value": {
          "x": "0",
          "y": "0.625rem",
          "blur": "0.625rem",
          "spread": "0",
          "color": "rgba(0,0,0,0.08)",
          "type": "dropShadow"
        },
        "type": "boxShadow",
        "attributes": {
          "category": "boxShadow"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": {
            "x": "0",
            "y": "0.625rem",
            "blur": "0.625rem",
            "spread": "0",
            "color": "rgba(0,0,0,0.08)",
            "type": "dropShadow"
          },
          "type": "boxShadow",
          "attributes": {
            "category": "boxShadow"
          }
        },
        "name": "box-shadow-10",
        "path": [
          "core",
          "box-shadow",
          "10"
        ]
      },
      "11": {
        "value": {
          "x": "0",
          "y": "0.688rem",
          "blur": "0.688rem",
          "spread": "0",
          "color": "rgba(0,0,0,0.07)",
          "type": "dropShadow"
        },
        "type": "boxShadow",
        "attributes": {
          "category": "boxShadow"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": {
            "x": "0",
            "y": "0.688rem",
            "blur": "0.688rem",
            "spread": "0",
            "color": "rgba(0,0,0,0.07)",
            "type": "dropShadow"
          },
          "type": "boxShadow",
          "attributes": {
            "category": "boxShadow"
          }
        },
        "name": "box-shadow-11",
        "path": [
          "core",
          "box-shadow",
          "11"
        ]
      },
      "12": {
        "value": {
          "x": "0",
          "y": "0.75rem",
          "blur": "0.75rem",
          "spread": "0",
          "color": "rgba(0,0,0,0.07)",
          "type": "dropShadow"
        },
        "type": "boxShadow",
        "attributes": {
          "category": "boxShadow"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": {
            "x": "0",
            "y": "0.75rem",
            "blur": "0.75rem",
            "spread": "0",
            "color": "rgba(0,0,0,0.07)",
            "type": "dropShadow"
          },
          "type": "boxShadow",
          "attributes": {
            "category": "boxShadow"
          }
        },
        "name": "box-shadow-12",
        "path": [
          "core",
          "box-shadow",
          "12"
        ]
      },
      "13": {
        "value": {
          "x": "0",
          "y": "0.813rem",
          "blur": "0.813rem",
          "spread": "0",
          "color": "rgba(0,0,0,0.07)",
          "type": "dropShadow"
        },
        "type": "boxShadow",
        "attributes": {
          "category": "boxShadow"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": {
            "x": "0",
            "y": "0.813rem",
            "blur": "0.813rem",
            "spread": "0",
            "color": "rgba(0,0,0,0.07)",
            "type": "dropShadow"
          },
          "type": "boxShadow",
          "attributes": {
            "category": "boxShadow"
          }
        },
        "name": "box-shadow-13",
        "path": [
          "core",
          "box-shadow",
          "13"
        ]
      },
      "14": {
        "value": {
          "x": "0",
          "y": "0.875rem",
          "blur": "0.875rem",
          "spread": "0",
          "color": "rgba(0,0,0,0.07)",
          "type": "dropShadow"
        },
        "type": "boxShadow",
        "attributes": {
          "category": "boxShadow"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": {
            "x": "0",
            "y": "0.875rem",
            "blur": "0.875rem",
            "spread": "0",
            "color": "rgba(0,0,0,0.07)",
            "type": "dropShadow"
          },
          "type": "boxShadow",
          "attributes": {
            "category": "boxShadow"
          }
        },
        "name": "box-shadow-14",
        "path": [
          "core",
          "box-shadow",
          "14"
        ]
      },
      "15": {
        "value": {
          "x": "0",
          "y": "0.938rem",
          "blur": "0.938rem",
          "spread": "0",
          "color": "rgba(0,0,0,0.07)",
          "type": "dropShadow"
        },
        "type": "boxShadow",
        "attributes": {
          "category": "boxShadow"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": {
            "x": "0",
            "y": "0.938rem",
            "blur": "0.938rem",
            "spread": "0",
            "color": "rgba(0,0,0,0.07)",
            "type": "dropShadow"
          },
          "type": "boxShadow",
          "attributes": {
            "category": "boxShadow"
          }
        },
        "name": "box-shadow-15",
        "path": [
          "core",
          "box-shadow",
          "15"
        ]
      },
      "16": {
        "value": {
          "x": "0",
          "y": "1rem",
          "blur": "1rem",
          "spread": "0",
          "color": "rgba(0,0,0,0.06)",
          "type": "dropShadow"
        },
        "type": "boxShadow",
        "attributes": {
          "category": "boxShadow"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": {
            "x": "0",
            "y": "1rem",
            "blur": "1rem",
            "spread": "0",
            "color": "rgba(0,0,0,0.06)",
            "type": "dropShadow"
          },
          "type": "boxShadow",
          "attributes": {
            "category": "boxShadow"
          }
        },
        "name": "box-shadow-16",
        "path": [
          "core",
          "box-shadow",
          "16"
        ]
      },
      "17": {
        "value": {
          "x": "0",
          "y": "1.063rem",
          "blur": "1.063rem",
          "spread": "0",
          "color": "rgba(0,0,0,0.06)",
          "type": "dropShadow"
        },
        "type": "boxShadow",
        "attributes": {
          "category": "boxShadow"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": {
            "x": "0",
            "y": "1.063rem",
            "blur": "1.063rem",
            "spread": "0",
            "color": "rgba(0,0,0,0.06)",
            "type": "dropShadow"
          },
          "type": "boxShadow",
          "attributes": {
            "category": "boxShadow"
          }
        },
        "name": "box-shadow-17",
        "path": [
          "core",
          "box-shadow",
          "17"
        ]
      },
      "18": {
        "value": {
          "x": "0",
          "y": "1.125rem",
          "blur": "1.125rem",
          "spread": "0",
          "color": "rgba(0,0,0,0.06)",
          "type": "dropShadow"
        },
        "type": "boxShadow",
        "attributes": {
          "category": "boxShadow"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": {
            "x": "0",
            "y": "1.125rem",
            "blur": "1.125rem",
            "spread": "0",
            "color": "rgba(0,0,0,0.06)",
            "type": "dropShadow"
          },
          "type": "boxShadow",
          "attributes": {
            "category": "boxShadow"
          }
        },
        "name": "box-shadow-18",
        "path": [
          "core",
          "box-shadow",
          "18"
        ]
      },
      "19": {
        "value": {
          "x": "0",
          "y": "1.188rem",
          "blur": "1.188rem",
          "spread": "0",
          "color": "rgba(0,0,0,0.06)",
          "type": "dropShadow"
        },
        "type": "boxShadow",
        "attributes": {
          "category": "boxShadow"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": {
            "x": "0",
            "y": "1.188rem",
            "blur": "1.188rem",
            "spread": "0",
            "color": "rgba(0,0,0,0.06)",
            "type": "dropShadow"
          },
          "type": "boxShadow",
          "attributes": {
            "category": "boxShadow"
          }
        },
        "name": "box-shadow-19",
        "path": [
          "core",
          "box-shadow",
          "19"
        ]
      },
      "20": {
        "value": {
          "x": "0",
          "y": "1.25rem",
          "blur": "1.25rem",
          "spread": "0",
          "color": "rgba(0,0,0,0.06)",
          "type": "dropShadow"
        },
        "type": "boxShadow",
        "attributes": {
          "category": "boxShadow"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": {
            "x": "0",
            "y": "1.25rem",
            "blur": "1.25rem",
            "spread": "0",
            "color": "rgba(0,0,0,0.06)",
            "type": "dropShadow"
          },
          "type": "boxShadow",
          "attributes": {
            "category": "boxShadow"
          }
        },
        "name": "box-shadow-20",
        "path": [
          "core",
          "box-shadow",
          "20"
        ]
      },
      "21": {
        "value": {
          "x": "0",
          "y": "1.313rem",
          "blur": "1.313rem",
          "spread": "0",
          "color": "rgba(0,0,0,0.05)",
          "type": "dropShadow"
        },
        "type": "boxShadow",
        "attributes": {
          "category": "boxShadow"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": {
            "x": "0",
            "y": "1.313rem",
            "blur": "1.313rem",
            "spread": "0",
            "color": "rgba(0,0,0,0.05)",
            "type": "dropShadow"
          },
          "type": "boxShadow",
          "attributes": {
            "category": "boxShadow"
          }
        },
        "name": "box-shadow-21",
        "path": [
          "core",
          "box-shadow",
          "21"
        ]
      },
      "22": {
        "value": {
          "x": "0",
          "y": "1.375rem",
          "blur": "1.375rem",
          "spread": "0",
          "color": "rgba(0,0,0,0.05)",
          "type": "dropShadow"
        },
        "type": "boxShadow",
        "attributes": {
          "category": "boxShadow"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": {
            "x": "0",
            "y": "1.375rem",
            "blur": "1.375rem",
            "spread": "0",
            "color": "rgba(0,0,0,0.05)",
            "type": "dropShadow"
          },
          "type": "boxShadow",
          "attributes": {
            "category": "boxShadow"
          }
        },
        "name": "box-shadow-22",
        "path": [
          "core",
          "box-shadow",
          "22"
        ]
      },
      "23": {
        "value": {
          "x": "0",
          "y": "1.438rem",
          "blur": "1.438rem",
          "spread": "0",
          "color": "rgba(0,0,0,0.05)",
          "type": "dropShadow"
        },
        "type": "boxShadow",
        "attributes": {
          "category": "boxShadow"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": {
            "x": "0",
            "y": "1.438rem",
            "blur": "1.438rem",
            "spread": "0",
            "color": "rgba(0,0,0,0.05)",
            "type": "dropShadow"
          },
          "type": "boxShadow",
          "attributes": {
            "category": "boxShadow"
          }
        },
        "name": "box-shadow-23",
        "path": [
          "core",
          "box-shadow",
          "23"
        ]
      },
      "24": {
        "value": {
          "x": "0",
          "y": "1.5rem",
          "blur": "1.5rem",
          "spread": "0",
          "color": "rgba(0,0,0,0.05)",
          "type": "dropShadow"
        },
        "type": "boxShadow",
        "attributes": {
          "category": "boxShadow"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": {
            "x": "0",
            "y": "1.5rem",
            "blur": "1.5rem",
            "spread": "0",
            "color": "rgba(0,0,0,0.05)",
            "type": "dropShadow"
          },
          "type": "boxShadow",
          "attributes": {
            "category": "boxShadow"
          }
        },
        "name": "box-shadow-24",
        "path": [
          "core",
          "box-shadow",
          "24"
        ]
      }
    },
    "letter-spacing": {
      "1": {
        "value": "-0.063rem",
        "type": "letterSpacing",
        "attributes": {
          "category": "size"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "-0.063rem",
          "type": "letterSpacing",
          "attributes": {
            "category": "size"
          }
        },
        "name": "letter-spacing-1",
        "path": [
          "core",
          "letter-spacing",
          "1"
        ]
      },
      "2": {
        "value": "-0.047rem",
        "type": "letterSpacing",
        "attributes": {
          "category": "size"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "-0.047rem",
          "type": "letterSpacing",
          "attributes": {
            "category": "size"
          }
        },
        "name": "letter-spacing-2",
        "path": [
          "core",
          "letter-spacing",
          "2"
        ]
      },
      "3": {
        "value": "-0.016rem",
        "type": "letterSpacing",
        "attributes": {
          "category": "size"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "-0.016rem",
          "type": "letterSpacing",
          "attributes": {
            "category": "size"
          }
        },
        "name": "letter-spacing-3",
        "path": [
          "core",
          "letter-spacing",
          "3"
        ]
      },
      "4": {
        "value": "0rem",
        "type": "letterSpacing",
        "attributes": {
          "category": "size"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "0rem",
          "type": "letterSpacing",
          "attributes": {
            "category": "size"
          }
        },
        "name": "letter-spacing-4",
        "path": [
          "core",
          "letter-spacing",
          "4"
        ]
      },
      "5": {
        "value": "0.016rem",
        "type": "letterSpacing",
        "attributes": {
          "category": "size"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "0.016rem",
          "type": "letterSpacing",
          "attributes": {
            "category": "size"
          }
        },
        "name": "letter-spacing-5",
        "path": [
          "core",
          "letter-spacing",
          "5"
        ]
      },
      "6": {
        "value": "0.031rem",
        "type": "letterSpacing",
        "attributes": {
          "category": "size"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "0.031rem",
          "type": "letterSpacing",
          "attributes": {
            "category": "size"
          }
        },
        "name": "letter-spacing-6",
        "path": [
          "core",
          "letter-spacing",
          "6"
        ]
      },
      "7": {
        "value": "0.047rem",
        "type": "letterSpacing",
        "attributes": {
          "category": "size"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "0.047rem",
          "type": "letterSpacing",
          "attributes": {
            "category": "size"
          }
        },
        "name": "letter-spacing-7",
        "path": [
          "core",
          "letter-spacing",
          "7"
        ]
      },
      "8": {
        "value": "0.063rem",
        "type": "letterSpacing",
        "attributes": {
          "category": "size"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "0.063rem",
          "type": "letterSpacing",
          "attributes": {
            "category": "size"
          }
        },
        "name": "letter-spacing-8",
        "path": [
          "core",
          "letter-spacing",
          "8"
        ]
      },
      "9": {
        "value": "0.078rem",
        "type": "letterSpacing",
        "attributes": {
          "category": "size"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "0.078rem",
          "type": "letterSpacing",
          "attributes": {
            "category": "size"
          }
        },
        "name": "letter-spacing-9",
        "path": [
          "core",
          "letter-spacing",
          "9"
        ]
      },
      "10": {
        "value": "0.094rem",
        "type": "letterSpacing",
        "attributes": {
          "category": "size"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "0.094rem",
          "type": "letterSpacing",
          "attributes": {
            "category": "size"
          }
        },
        "name": "letter-spacing-10",
        "path": [
          "core",
          "letter-spacing",
          "10"
        ]
      },
      "11": {
        "value": "0.109rem",
        "type": "letterSpacing",
        "attributes": {
          "category": "size"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "0.109rem",
          "type": "letterSpacing",
          "attributes": {
            "category": "size"
          }
        },
        "name": "letter-spacing-11",
        "path": [
          "core",
          "letter-spacing",
          "11"
        ]
      },
      "12": {
        "value": "0.125rem",
        "type": "letterSpacing",
        "attributes": {
          "category": "size"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "0.125rem",
          "type": "letterSpacing",
          "attributes": {
            "category": "size"
          }
        },
        "name": "letter-spacing-12",
        "path": [
          "core",
          "letter-spacing",
          "12"
        ]
      }
    },
    "uppercase": {
      "value": "uppercase",
      "type": "textCase",
      "attributes": {},
      "filePath": "tokens/tokens.json",
      "isSource": true,
      "original": {
        "value": "uppercase",
        "type": "textCase",
        "attributes": {}
      },
      "name": "uppercase",
      "path": [
        "core",
        "uppercase"
      ]
    },
    "navy": {
      "100": {
        "value": "#FCFCFC",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#FCFCFC",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "navy-100",
        "path": [
          "core",
          "navy",
          "100"
        ]
      },
      "200": {
        "value": "#F5F6F7",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#F5F6F7",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "navy-200",
        "path": [
          "core",
          "navy",
          "200"
        ]
      },
      "300": {
        "value": "#E9EBED",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#E9EBED",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "navy-300",
        "path": [
          "core",
          "navy",
          "300"
        ]
      },
      "400": {
        "value": "#DADDE1",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#DADDE1",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "navy-400",
        "path": [
          "core",
          "navy",
          "400"
        ]
      },
      "500": {
        "value": "#B8BEC7",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#B8BEC7",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "navy-500",
        "path": [
          "core",
          "navy",
          "500"
        ]
      },
      "600": {
        "value": "#808C9D",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#808C9D",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "navy-600",
        "path": [
          "core",
          "navy",
          "600"
        ]
      },
      "700": {
        "value": "#617087",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#617087",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "navy-700",
        "path": [
          "core",
          "navy",
          "700"
        ]
      },
      "800": {
        "value": "#485A76",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#485A76",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "navy-800",
        "path": [
          "core",
          "navy",
          "800"
        ]
      },
      "900": {
        "value": "#354A68",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#354A68",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "navy-900",
        "path": [
          "core",
          "navy",
          "900"
        ]
      },
      "1000": {
        "value": "#1E3558",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#1E3558",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "navy-1000",
        "path": [
          "core",
          "navy",
          "1000"
        ]
      },
      "1100": {
        "value": "#092348",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#092348",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "navy-1100",
        "path": [
          "core",
          "navy",
          "1100"
        ]
      },
      "1200": {
        "value": "#001035",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#001035",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "navy-1200",
        "path": [
          "core",
          "navy",
          "1200"
        ]
      }
    },
    "navy-dark": {
      "100": {
        "value": "#030407",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#030407",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "navy-dark-100",
        "path": [
          "core",
          "navy-dark",
          "100"
        ]
      },
      "200": {
        "value": "#0e101b",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#0e101b",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "navy-dark-200",
        "path": [
          "core",
          "navy-dark",
          "200"
        ]
      },
      "300": {
        "value": "#101321",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#101321",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "navy-dark-300",
        "path": [
          "core",
          "navy-dark",
          "300"
        ]
      },
      "400": {
        "value": "#1b294a",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#1b294a",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "navy-dark-400",
        "path": [
          "core",
          "navy-dark",
          "400"
        ]
      },
      "500": {
        "value": "#293658",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#293658",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "navy-dark-500",
        "path": [
          "core",
          "navy-dark",
          "500"
        ]
      },
      "600": {
        "value": "#5d6583",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#5d6583",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "navy-dark-600",
        "path": [
          "core",
          "navy-dark",
          "600"
        ]
      },
      "700": {
        "value": "#7d839c",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#7d839c",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "navy-dark-700",
        "path": [
          "core",
          "navy-dark",
          "700"
        ]
      },
      "800": {
        "value": "#9397ad",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#9397ad",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "navy-dark-800",
        "path": [
          "core",
          "navy-dark",
          "800"
        ]
      },
      "900": {
        "value": "#a7aabc",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#a7aabc",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "navy-dark-900",
        "path": [
          "core",
          "navy-dark",
          "900"
        ]
      },
      "1000": {
        "value": "#c3c5d2",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#c3c5d2",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "navy-dark-1000",
        "path": [
          "core",
          "navy-dark",
          "1000"
        ]
      },
      "1100": {
        "value": "#e6e7ec",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#e6e7ec",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "navy-dark-1100",
        "path": [
          "core",
          "navy-dark",
          "1100"
        ]
      },
      "1200": {
        "value": "#f2f3f5",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#f2f3f5",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "navy-dark-1200",
        "path": [
          "core",
          "navy-dark",
          "1200"
        ]
      }
    },
    "seafoam": {
      "100": {
        "value": "#F2FFFB",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#F2FFFB",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "seafoam-100",
        "path": [
          "core",
          "seafoam",
          "100"
        ]
      },
      "200": {
        "value": "#D7FFF3",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#D7FFF3",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "seafoam-200",
        "path": [
          "core",
          "seafoam",
          "200"
        ]
      },
      "300": {
        "value": "#91FFE1",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#91FFE1",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "seafoam-300",
        "path": [
          "core",
          "seafoam",
          "300"
        ]
      },
      "400": {
        "value": "#07F9CC",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#07F9CC",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "seafoam-400",
        "path": [
          "core",
          "seafoam",
          "400"
        ]
      },
      "500": {
        "value": "#1FC7A3",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#1FC7A3",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "seafoam-500",
        "path": [
          "core",
          "seafoam",
          "500"
        ]
      },
      "600": {
        "value": "#249D81",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#249D81",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "seafoam-600",
        "path": [
          "core",
          "seafoam",
          "600"
        ]
      },
      "700": {
        "value": "#237D68",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#237D68",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "seafoam-700",
        "path": [
          "core",
          "seafoam",
          "700"
        ]
      },
      "800": {
        "value": "#216453",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#216453",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "seafoam-800",
        "path": [
          "core",
          "seafoam",
          "800"
        ]
      },
      "900": {
        "value": "#1E5144",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#1E5144",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "seafoam-900",
        "path": [
          "core",
          "seafoam",
          "900"
        ]
      },
      "1000": {
        "value": "#1A3B31",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#1A3B31",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "seafoam-1000",
        "path": [
          "core",
          "seafoam",
          "1000"
        ]
      },
      "1100": {
        "value": "#142722",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#142722",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "seafoam-1100",
        "path": [
          "core",
          "seafoam",
          "1100"
        ]
      },
      "1200": {
        "value": "#0C1512",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#0C1512",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "seafoam-1200",
        "path": [
          "core",
          "seafoam",
          "1200"
        ]
      }
    },
    "seafoam-dark": {
      "100": {
        "value": "#020403",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#020403",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "seafoam-dark-100",
        "path": [
          "core",
          "seafoam-dark",
          "100"
        ]
      },
      "200": {
        "value": "#0A1310",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#0A1310",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "seafoam-dark-200",
        "path": [
          "core",
          "seafoam-dark",
          "200"
        ]
      },
      "300": {
        "value": "#101C18",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#101C18",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "seafoam-dark-300",
        "path": [
          "core",
          "seafoam-dark",
          "300"
        ]
      },
      "400": {
        "value": "#162E27",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#162E27",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "seafoam-dark-400",
        "path": [
          "core",
          "seafoam-dark",
          "400"
        ]
      },
      "500": {
        "value": "#1A3D33",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#1A3D33",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "seafoam-dark-500",
        "path": [
          "core",
          "seafoam-dark",
          "500"
        ]
      },
      "600": {
        "value": "#22725E",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#22725E",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "seafoam-dark-600",
        "path": [
          "core",
          "seafoam-dark",
          "600"
        ]
      },
      "700": {
        "value": "#24947A",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#24947A",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "seafoam-dark-700",
        "path": [
          "core",
          "seafoam-dark",
          "700"
        ]
      },
      "800": {
        "value": "#23AB8D",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#23AB8D",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "seafoam-dark-800",
        "path": [
          "core",
          "seafoam-dark",
          "800"
        ]
      },
      "900": {
        "value": "#20C19E",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#20C19E",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "seafoam-dark-900",
        "path": [
          "core",
          "seafoam-dark",
          "900"
        ]
      },
      "1000": {
        "value": "#18E0B8",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#18E0B8",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "seafoam-dark-1000",
        "path": [
          "core",
          "seafoam-dark",
          "1000"
        ]
      },
      "1100": {
        "value": "#78FFDC",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#78FFDC",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "seafoam-dark-1100",
        "path": [
          "core",
          "seafoam-dark",
          "1100"
        ]
      },
      "1200": {
        "value": "#C8FFEF",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#C8FFEF",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "seafoam-dark-1200",
        "path": [
          "core",
          "seafoam-dark",
          "1200"
        ]
      }
    },
    "sf-pro-condensed-width": {
      "value": "70",
      "type": "paragraphSpacing",
      "attributes": {},
      "filePath": "tokens/tokens.json",
      "isSource": true,
      "original": {
        "value": "70",
        "type": "paragraphSpacing",
        "attributes": {}
      },
      "name": "sf-pro-condensed-width",
      "path": [
        "core",
        "sf-pro-condensed-width"
      ]
    },
    "roboto-flex-variables": {
      "value": "'GRAD' 0, 'slnt' 0, 'XTRA' 468, 'XOPQ' 96, 'YOPQ' 79, 'YTLC' 484, 'YTUC' 672, 'YTAS' 750, 'YTDE' -203, 'wdth' 25, 'YTFI' 738, 'opsz' 18",
      "type": "paragraphSpacing",
      "attributes": {},
      "filePath": "tokens/tokens.json",
      "isSource": true,
      "original": {
        "value": "'GRAD' 0, 'slnt' 0, 'XTRA' 468, 'XOPQ' 96, 'YOPQ' 79, 'YTLC' 484, 'YTUC' 672, 'YTAS' 750, 'YTDE' -203, 'wdth' 25, 'YTFI' 738, 'opsz' 18",
        "type": "paragraphSpacing",
        "attributes": {}
      },
      "name": "roboto-flex-variables",
      "path": [
        "core",
        "roboto-flex-variables"
      ]
    },
    "pumpkin": {
      "100": {
        "value": "#FFFCF8",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#FFFCF8",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "pumpkin-100",
        "path": [
          "core",
          "pumpkin",
          "100"
        ]
      },
      "200": {
        "value": "#FFF5EB",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#FFF5EB",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "pumpkin-200",
        "path": [
          "core",
          "pumpkin",
          "200"
        ]
      },
      "300": {
        "value": "#FFE7D1",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#FFE7D1",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "pumpkin-300",
        "path": [
          "core",
          "pumpkin",
          "300"
        ]
      },
      "400": {
        "value": "#FFD5AF",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#FFD5AF",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "pumpkin-400",
        "path": [
          "core",
          "pumpkin",
          "400"
        ]
      },
      "500": {
        "value": "#FD9839",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#FD9839",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "pumpkin-500",
        "path": [
          "core",
          "pumpkin",
          "500"
        ]
      },
      "600": {
        "value": "#C77930",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#C77930",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "pumpkin-600",
        "path": [
          "core",
          "pumpkin",
          "600"
        ]
      },
      "700": {
        "value": "#9F6129",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#9F6129",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "pumpkin-700",
        "path": [
          "core",
          "pumpkin",
          "700"
        ]
      },
      "800": {
        "value": "#7F4E23",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#7F4E23",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "pumpkin-800",
        "path": [
          "core",
          "pumpkin",
          "800"
        ]
      },
      "900": {
        "value": "#67401E",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#67401E",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "pumpkin-900",
        "path": [
          "core",
          "pumpkin",
          "900"
        ]
      },
      "1000": {
        "value": "#492F18",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#492F18",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "pumpkin-1000",
        "path": [
          "core",
          "pumpkin",
          "1000"
        ]
      },
      "1100": {
        "value": "#312013",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#312013",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "pumpkin-1100",
        "path": [
          "core",
          "pumpkin",
          "1100"
        ]
      },
      "1200": {
        "value": "#1A1209",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#1A1209",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "pumpkin-1200",
        "path": [
          "core",
          "pumpkin",
          "1200"
        ]
      }
    },
    "pumpkin-dark": {
      "100": {
        "value": "#060302",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#060302",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "pumpkin-dark-100",
        "path": [
          "core",
          "pumpkin-dark",
          "100"
        ]
      },
      "200": {
        "value": "#170F08",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#170F08",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "pumpkin-dark-200",
        "path": [
          "core",
          "pumpkin-dark",
          "200"
        ]
      },
      "300": {
        "value": "#22170D",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#22170D",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "pumpkin-dark-300",
        "path": [
          "core",
          "pumpkin-dark",
          "300"
        ]
      },
      "400": {
        "value": "#3A2615",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#3A2615",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "pumpkin-dark-400",
        "path": [
          "core",
          "pumpkin-dark",
          "400"
        ]
      },
      "500": {
        "value": "#4D3119",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#4D3119",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "pumpkin-dark-500",
        "path": [
          "core",
          "pumpkin-dark",
          "500"
        ]
      },
      "600": {
        "value": "#905826",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#905826",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "pumpkin-dark-600",
        "path": [
          "core",
          "pumpkin-dark",
          "600"
        ]
      },
      "700": {
        "value": "#BC722E",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#BC722E",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "pumpkin-dark-700",
        "path": [
          "core",
          "pumpkin-dark",
          "700"
        ]
      },
      "800": {
        "value": "#DA8433",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#DA8433",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "pumpkin-dark-800",
        "path": [
          "core",
          "pumpkin-dark",
          "800"
        ]
      },
      "900": {
        "value": "#F59337",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#F59337",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "pumpkin-dark-900",
        "path": [
          "core",
          "pumpkin-dark",
          "900"
        ]
      },
      "1000": {
        "value": "#FFB77C",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#FFB77C",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "pumpkin-dark-1000",
        "path": [
          "core",
          "pumpkin-dark",
          "1000"
        ]
      },
      "1100": {
        "value": "#FFE3CA",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#FFE3CA",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "pumpkin-dark-1100",
        "path": [
          "core",
          "pumpkin-dark",
          "1100"
        ]
      },
      "1200": {
        "value": "#FFF1E5",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#FFF1E5",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "pumpkin-dark-1200",
        "path": [
          "core",
          "pumpkin-dark",
          "1200"
        ]
      }
    },
    "cyan": {
      "100": {
        "value": "#F4FEFF",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#F4FEFF",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "cyan-100",
        "path": [
          "core",
          "cyan",
          "100"
        ]
      },
      "200": {
        "value": "#DCFDFF",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#DCFDFF",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "cyan-200",
        "path": [
          "core",
          "cyan",
          "200"
        ]
      },
      "300": {
        "value": "#A7F9FF",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#A7F9FF",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "cyan-300",
        "path": [
          "core",
          "cyan",
          "300"
        ]
      },
      "400": {
        "value": "#3BF3FF",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#3BF3FF",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "cyan-400",
        "path": [
          "core",
          "cyan",
          "400"
        ]
      },
      "500": {
        "value": "#29C2CD",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#29C2CD",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "cyan-500",
        "path": [
          "core",
          "cyan",
          "500"
        ]
      },
      "600": {
        "value": "#2A99A1",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#2A99A1",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "cyan-600",
        "path": [
          "core",
          "cyan",
          "600"
        ]
      },
      "700": {
        "value": "#287A80",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#287A80",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "cyan-700",
        "path": [
          "core",
          "cyan",
          "700"
        ]
      },
      "800": {
        "value": "#246267",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#246267",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "cyan-800",
        "path": [
          "core",
          "cyan",
          "800"
        ]
      },
      "900": {
        "value": "#215053",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#215053",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "cyan-900",
        "path": [
          "core",
          "cyan",
          "900"
        ]
      },
      "1000": {
        "value": "#1B393C",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#1B393C",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "cyan-1000",
        "path": [
          "core",
          "cyan",
          "1000"
        ]
      },
      "1100": {
        "value": "#152628",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#152628",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "cyan-1100",
        "path": [
          "core",
          "cyan",
          "1100"
        ]
      },
      "1200": {
        "value": "#0D1515",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#0D1515",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "cyan-1200",
        "path": [
          "core",
          "cyan",
          "1200"
        ]
      }
    },
    "cyan-dark": {
      "100": {
        "value": "#020404",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#020404",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "cyan-dark-100",
        "path": [
          "core",
          "cyan-dark",
          "100"
        ]
      },
      "200": {
        "value": "#0B1213",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#0B1213",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "cyan-dark-200",
        "path": [
          "core",
          "cyan-dark",
          "200"
        ]
      },
      "300": {
        "value": "#111a1b",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#111a1b",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "cyan-dark-300",
        "path": [
          "core",
          "cyan-dark",
          "300"
        ]
      },
      "400": {
        "value": "#162d2f",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#162d2f",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "cyan-dark-400",
        "path": [
          "core",
          "cyan-dark",
          "400"
        ]
      },
      "500": {
        "value": "#1C3C3E",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#1C3C3E",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "cyan-dark-500",
        "path": [
          "core",
          "cyan-dark",
          "500"
        ]
      },
      "600": {
        "value": "#266F75",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#266F75",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "cyan-dark-600",
        "path": [
          "core",
          "cyan-dark",
          "600"
        ]
      },
      "700": {
        "value": "#299198",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#299198",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "cyan-dark-700",
        "path": [
          "core",
          "cyan-dark",
          "700"
        ]
      },
      "800": {
        "value": "#2AA8B0",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#2AA8B0",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "cyan-dark-800",
        "path": [
          "core",
          "cyan-dark",
          "800"
        ]
      },
      "900": {
        "value": "#29BDC6",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#29BDC6",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "cyan-dark-900",
        "path": [
          "core",
          "cyan-dark",
          "900"
        ]
      },
      "1000": {
        "value": "#26DBE6",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#26DBE6",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "cyan-dark-1000",
        "path": [
          "core",
          "cyan-dark",
          "1000"
        ]
      },
      "1100": {
        "value": "#98F8FF",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#98F8FF",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "cyan-dark-1100",
        "path": [
          "core",
          "cyan-dark",
          "1100"
        ]
      },
      "1200": {
        "value": "#D0FCFF",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#D0FCFF",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "cyan-dark-1200",
        "path": [
          "core",
          "cyan-dark",
          "1200"
        ]
      }
    },
    "cream": {
      "100": {
        "value": "#FFFAF1",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#FFFAF1",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "cream-100",
        "path": [
          "core",
          "cream",
          "100"
        ]
      },
      "200": {
        "value": "#fff4e0",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#fff4e0",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "cream-200",
        "path": [
          "core",
          "cream",
          "200"
        ]
      },
      "300": {
        "value": "#ffe8bf",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#ffe8bf",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "cream-300",
        "path": [
          "core",
          "cream",
          "300"
        ]
      },
      "400": {
        "value": "#fed6a1",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#fed6a1",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "cream-400",
        "path": [
          "core",
          "cream",
          "400"
        ]
      },
      "500": {
        "value": "#d0aa76",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#d0aa76",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "cream-500",
        "path": [
          "core",
          "cream",
          "500"
        ]
      },
      "600": {
        "value": "#a5865c",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#a5865c",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "cream-600",
        "path": [
          "core",
          "cream",
          "600"
        ]
      },
      "700": {
        "value": "#846b4a",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#846b4a",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "cream-700",
        "path": [
          "core",
          "cream",
          "700"
        ]
      },
      "800": {
        "value": "#69563b",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#69563b",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "cream-800",
        "path": [
          "core",
          "cream",
          "800"
        ]
      },
      "900": {
        "value": "#564632",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#564632",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "cream-900",
        "path": [
          "core",
          "cream",
          "900"
        ]
      },
      "1000": {
        "value": "#3e3325",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#3e3325",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "cream-1000",
        "path": [
          "core",
          "cream",
          "1000"
        ]
      },
      "1100": {
        "value": "#29231b",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#29231b",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "cream-1100",
        "path": [
          "core",
          "cream",
          "1100"
        ]
      },
      "1200": {
        "value": "#16120e",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#16120e",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "cream-1200",
        "path": [
          "core",
          "cream",
          "1200"
        ]
      }
    },
    "cream-dark": {
      "100": {
        "value": "#040303",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#040303",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "cream-dark-100",
        "path": [
          "core",
          "cream-dark",
          "100"
        ]
      },
      "200": {
        "value": "#13100d",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#13100d",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "cream-dark-200",
        "path": [
          "core",
          "cream-dark",
          "200"
        ]
      },
      "300": {
        "value": "#1c1813",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#1c1813",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "cream-dark-300",
        "path": [
          "core",
          "cream-dark",
          "300"
        ]
      },
      "400": {
        "value": "#30281e",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#30281e",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "cream-dark-400",
        "path": [
          "core",
          "cream-dark",
          "400"
        ]
      },
      "500": {
        "value": "#413527",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#413527",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "cream-dark-500",
        "path": [
          "core",
          "cream-dark",
          "500"
        ]
      },
      "600": {
        "value": "#796244",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#796244",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "cream-dark-600",
        "path": [
          "core",
          "cream-dark",
          "600"
        ]
      },
      "700": {
        "value": "#9d7f57",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#9d7f57",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "cream-dark-700",
        "path": [
          "core",
          "cream-dark",
          "700"
        ]
      },
      "800": {
        "value": "#b49365",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#b49365",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "cream-dark-800",
        "path": [
          "core",
          "cream-dark",
          "800"
        ]
      },
      "900": {
        "value": "#caa572",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#caa572",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "cream-dark-900",
        "path": [
          "core",
          "cream-dark",
          "900"
        ]
      },
      "1000": {
        "value": "#e8bf87",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#e8bf87",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "cream-dark-1000",
        "path": [
          "core",
          "cream-dark",
          "1000"
        ]
      },
      "1100": {
        "value": "#ffe4b8",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#ffe4b8",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "cream-dark-1100",
        "path": [
          "core",
          "cream-dark",
          "1100"
        ]
      },
      "1200": {
        "value": "#fff1d6",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "#fff1d6",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "cream-dark-1200",
        "path": [
          "core",
          "cream-dark",
          "1200"
        ]
      }
    },
    "gradient": {
      "yellow": {
        "value": "linear-gradient(180deg, #E9DB93 0%, #FDFAA3 4%, #FCE367 45%, #E2AC43 95%, #B88624 100%);",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "linear-gradient(180deg, #E9DB93 0%, #FDFAA3 4%, #FCE367 45%, #E2AC43 95%, #B88624 100%);",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "gradient-yellow",
        "path": [
          "core",
          "gradient",
          "yellow"
        ]
      },
      "yellow-dark": {
        "value": "linear-gradient(180deg, #fffdf2 0%, #FDFAA3 16.5%, #FCE367 42%, #E2AC43 94%, #fdf18b 98%);",
        "type": "color",
        "attributes": {
          "category": "color"
        },
        "filePath": "tokens/tokens.json",
        "isSource": true,
        "original": {
          "value": "linear-gradient(180deg, #fffdf2 0%, #FDFAA3 16.5%, #FCE367 42%, #E2AC43 94%, #fdf18b 98%);",
          "type": "color",
          "attributes": {
            "category": "color"
          }
        },
        "name": "gradient-yellow-dark",
        "path": [
          "core",
          "gradient",
          "yellow-dark"
        ]
      }
    },
    "rogue": {
      "midnight-dark": {
        "100": {
          "value": "#15213D",
          "type": "color",
          "attributes": {
            "category": "color"
          },
          "filePath": "tokens/tokens.json",
          "isSource": true,
          "original": {
            "value": "#15213D",
            "type": "color",
            "attributes": {
              "category": "color"
            }
          },
          "name": "rogue-midnight-dark-100",
          "path": [
            "core",
            "rogue",
            "midnight-dark",
            "100"
          ]
        },
        "200": {
          "value": "#192747",
          "type": "color",
          "attributes": {
            "category": "color"
          },
          "filePath": "tokens/tokens.json",
          "isSource": true,
          "original": {
            "value": "#192747",
            "type": "color",
            "attributes": {
              "category": "color"
            }
          },
          "name": "rogue-midnight-dark-200",
          "path": [
            "core",
            "rogue",
            "midnight-dark",
            "200"
          ]
        },
        "300": {
          "value": "#1C2C50",
          "type": "color",
          "attributes": {
            "category": "color"
          },
          "filePath": "tokens/tokens.json",
          "isSource": true,
          "original": {
            "value": "#1C2C50",
            "type": "color",
            "attributes": {
              "category": "color"
            }
          },
          "name": "rogue-midnight-dark-300",
          "path": [
            "core",
            "rogue",
            "midnight-dark",
            "300"
          ]
        }
      }
    }
  }
};